<template>
<div class="tw_documents">
  <tw-collapse title="Attached Files" :initialOpen="documents && documents.length > 0" ref="collapse">
    <div class="attachment">

      <el-checkbox
        v-if="downloadableDocuments && downloadableDocuments.length > 0"
        :indeterminate="!selectAllcheckboxIsSelected && (selectedDocumentsForDownload.length > 0)"
        v-model="selectAllcheckboxIsSelected">
        {{ $t('Common.Select All') }}
      </el-checkbox>

      <div class="file" v-for="(doc, index) in documents" :key="index">
        <div class="user">
          <figure class="avatar table"><img :src="doc.updateUserIcon"></figure>
          <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
            <div slot="content">{{doc.updateUserName}}</div>
            <div class="from_name">{{doc.updateUserName}}</div>
          </el-tooltip>
        </div>

        <div class="inner">
          <div v-if="doc.deleteFlg === DELETE_FLG.DELETED && !doc.documentPath" style="display: flex;">
            <!-- アーカイブ一覧から遷移して表示するアーカイブのプロセス詳細画面では削除済みの添付ファイルは表示される。削除済みの添付ファイルについてはダウンロード不可とするためチェックボックスはなし。 -->
            <span class="file_name">{{doc.documentName}}</span>
            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">PDF Preview</div>
              <img v-if="doc.deleteFlg !== DELETE_FLG.DELETED && doc.documentPath && s.isPdf(doc)" @click="s.previewPdf(doc)" style="cursor:pointer;" src="@/assets/images/icons/icon_pdf_preview.svg">
            </el-tooltip>
          </div>
          <div v-else style="display: flex; align-items: center;">
            <el-checkbox style="display: flex;" v-model="selectedDocumentsForDownload" :label="doc">
              <span class="file_name">{{doc.documentName}}</span>
            </el-checkbox>
            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">PDF Preview</div>
              <img v-if="doc.deleteFlg !== DELETE_FLG.DELETED && doc.documentPath && s.isPdf(doc)" @click="s.previewPdf(doc)" style="cursor:pointer;" src="@/assets/images/icons/icon_pdf_preview.svg">
            </el-tooltip>
          </div>
          <div style="display: flex;justify-content:space-between;align-items:center;padding-bottom: 8px">
            {{doc.updateDate | dateTimeFormat}},&nbsp;{{doc.documentSize | fileSize}}
          </div>
          <div>
            <template v-for="(item, index) in DOCUMENT_LINK_VARIABLES">
              <el-tag v-if="doc['documentLink' + getDocumentKey(item.code)] === ATTACHED_FILE_FLG.ON" :key="index" type="info" class="file-category-tag">
                <el-tooltip v-if="/^Others\d+/.test(item.label) && doc['documentLinkLabel' + getDocumentKey(item.code)]" placement="top" popper-class="mcu">
                  <div slot="content">{{doc['documentLinkLabel' + getDocumentKey(item.code)]}}</div>
                  <span>{{ item.label }}</span>
                </el-tooltip> 
                <span v-else>{{ item.label }}</span>        
              </el-tag>
            </template>
          </div>
          <div v-if="doc.documentCommentText" class="comment"  v-html="sanitize(doc.documentCommentText)" />
        </div>
      </div>
      <div v-if="!documents.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
    </div>
  </tw-collapse>

  <!--  TODO: ボタンの出しわけが正しいかどうか確認する-->
  <div class="attached-files-header-buttons">
    <el-tooltip v-if='isShowEditAttachedFilesButton' placement="top" popper-class="mcu" :tabindex="-1">
      <div slot="content">Edit Attached Files</div>
      <tw-button type="secondary" size="medium" icon="edit_attached_files" @click="onOpenEditAttachedFilesModal" :disabled="!(selectedDocumentsForDownload.length <= 0)" />
    </el-tooltip>

    <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
      <div slot="content">Download</div>
      <tw-button v-if='downloadableDocuments.length' type='secondary' size='medium' icon='download' @click='downloadSelectedDocuments()' :disabled='selectedDocumentsForDownload.length <= 0' />
    </el-tooltip>
  </div>

  <el-dialog v-show='s.pdfSrc === null && this.isShowEditAttachedFilesModal == true'
              title='Edit Attached Files'
             :visible.sync='isShowEditAttachedFilesModal'
             :close-on-click-modal='false'
             :before-close='closeEditAttachedFilesModal'
             :class="{'header_border': s.pdfSrc === null, 'hide-modal': s.pdfSrc !== null}"
             width='944px'
             aria-label='Select Customer'
             :append-to-body='true'  
  >
    <tw-edit-attached-files-section ref='twEditAttachedFilesSection'
                                    @onSuccess='disableEditAttachedFilesModal'
                                    @update-attached-files="updateAttachedFiles"
    />

  </el-dialog>
</div>
</template>

<script>
import _ from 'lodash';
import {
  DELETE_FLG,
  PROCESS_TYPE,
  CO_DOCUMENT_ID_VARIABLES, PROCESS_PROGRESS_STATUS,
  DOCUMENT_LINK_VARIABLES,
  ATTACHED_FILE_FLG
} from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import TwButton from '@/components/atoms/TwButton';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwEditAttachedFilesSection from './TwEditAttachedFilesSection.vue';

export default {
  name: 'TwDocuments',
  inject: ['s'],
  props: {
    items: Object
  },
  components: {
    TwButton,
    TwCollapse,
    TwEditAttachedFilesSection
  },
  data() {
    return {
      DELETE_FLG,
      ATTACHED_FILE_FLG,
      DOCUMENT_LINK_VARIABLES,
      isShowEditAttachedFilesModal: false,
      entityId: this.entityId,
      processId: this.processId,
      processSeq: this.processSeq,
      selectedDocumentsForDownload: []
    };
  },
  computed: {
    documents() { // 削除済みの添付ファイルも含む。アーカイブ一覧から遷移して表示するアーカイブのプロセス詳細画面では削除済みの添付ファイルも表示される。削除済みの添付ファイルについてはダウンロード不可とする。
      return _.get(this, 's.res.documents') || [];
    },
    downloadableDocuments() { // 削除済みの添付ファイルは含まれない。最新のプロセス詳細画面では削除済みの添付ファイルは表示されない。
      return _.reject(this.documents, doc => {
        return doc.deleteFlg === DELETE_FLG.DELETED && !doc.documentPath;
      });
    },
    selectAllcheckboxIsSelected: {
      get() {
        return this.selectedDocumentsForDownload.length === this.downloadableDocuments.length;
      },
      set(value) {
        if (value) {
          this.selectedDocumentsForDownload = this.downloadableDocuments;
        } else {
          this.selectedDocumentsForDownload = [];
        }
      }
    },
    sanitize() {
      return string => {
        const documentId = _.find(CO_DOCUMENT_ID_VARIABLES, {code: string})
        if ((this.s.processType === PROCESS_TYPE.FDCOR || this.s.processType === PROCESS_TYPE.FDCOI) && documentId) {
          string = documentId.label;
        }
        return this.$sanitize(string).replace(/\n/g, '<br>');
      }
    },
    isShowEditAttachedFilesButton() {
      if (
        this.s.processType !== PROCESS_TYPE.ECREQ
        && this.s.processType !== PROCESS_TYPE.COCOS
        && this.s.processType !== PROCESS_TYPE.ICREQ
      ) {
        return false;
      }

      if (
        this.s.tradeManagement.processProgressStatus !== PROCESS_PROGRESS_STATUS.F_INPUT_IN_PROGRESS
        && this.s.tradeManagement.processProgressStatus !== PROCESS_PROGRESS_STATUS.T_AWAITING_CONFIRMATION
      ) {
        return false;
      }

      if (!this.$store.getters.isPic) {
        return false;
      }

      if (this.s.isArchive) {
        return false;
      }

      return true;
    }
  },
  watch: {
    documents(val) {
      if (val && val.length > 0) {
        this.$refs.collapse.open = true;
      }
    }
  },
  methods: {
    download(doc) {
      const path = {
        documentManageId: doc.documentManageId,
        documentTrx: doc.documentTrx
      };

      $api
      .download(path, doc.documentName)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    downloadSelectedDocuments() {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to download the selected files? \nIt may take some time to complete the download.')
        .then(async () => {
          for(const doc of this.selectedDocumentsForDownload) {
            await new Promise((resolve) => {
              setTimeout(() => {
                this.download(doc);
                resolve();
              }, 1000)
            })
          }
          this.selectedDocumentsForDownload = [];
        })
        .catch(() => {});
    },
    onOpenEditAttachedFilesModal() {
      this.isShowEditAttachedFilesModal = true;
      this.$nextTick(() => {
        if (this.$refs.twEditAttachedFilesSection) this.$refs.twEditAttachedFilesSection.fetchUploadedFiles();
      });
    },
    closeEditAttachedFilesModal(done) {
      this.$nextTick(() => {
        // モーダル上でファイル操作（添付・削除・コメント修正）をしていなければ、ポップアップを出さずにモーダルを閉じる
        if (
          this.$refs.twEditAttachedFilesSection &&
          !this.$refs.twEditAttachedFilesSection.fileActionOccurred
        ) {
          this.$refs.twEditAttachedFilesSection.resetDialog();
          done();
          return;
        }

        // モーダル上でファイル操作をしていれば、モーダルを閉じる際にポップアップを表示する
        this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to leave?\nChanges that you made will not be applied.')
          .then(() => {
              if (this.$refs.twEditAttachedFilesSection) {
                this.$refs.twEditAttachedFilesSection.resetDialog();
              }
            done();
          })
          .catch(() => {});
      });
    },
    updateAttachedFiles() {
      this.$emit('update-attached-files');
    },
    disableEditAttachedFilesModal() {
      this.isShowEditAttachedFilesModal = false;
      this.$nextTick(() => {
        if (this.$refs.twEditAttachedFilesSection) this.$refs.twEditAttachedFilesSection.resetDialog();
      });
    },
    getDocumentKey(code){
      const Key = {
        1: "Inv",
        2: "Pl",
        3: "Bl",
        4: "An",
        5: "Si",
        6: "Co",
        7: "Others1",
        8: "Others2",
        9: "Others3",
        10: "Others4",
        11: "Others5"
      };
      return Key[code] || "";
    }
  },
};
</script>

<style lang="scss" scoped>
.tw_documents {
  position: relative;
  margin-bottom: 16px;

  .attachment {
    max-height: 410px;
    overflow-y: auto;
    padding: 4px 16px 27px;
    font-size: 14px;
    line-height: 20px;

    .file {
      .user {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 4px;

        font-size: 12px;
        line-height: 20px;
        color: $color_black;

        .from_name {
          white-space:nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .inner {
        width: 100%;
        min-height: 55px;
        margin-top: 8px;
        padding: 8px 8px 0 16px;
        border-radius: 6px;
        background: $color_gray_100;
      }

      span.file_name {
        display: block;
        margin-right: 10px;
        text-decoration: none;
        font-size: 14px;
        text-wrap: wrap;
        line-height: 1.2;
      }

      .comment {
        margin-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.empty_message {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: $color_black;
  margin-top: 33px;
  margin-bottom: 0px;
}

.edit-attached-files {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  margin-bottom: -16px;
}

.attached-files-header-buttons {
  position: absolute;
  top: 10.5px;
  right: 16px;
  display: flex;
  gap: 8px;

  .tw_button + .tw_button {
    margin-left: 0;
  }
}
.file-category-tag {
  margin-right: 5px;
  align-items: center;
  text-align: center;
  height: auto;
  color: #333;
  border-color: #d3d4d6;
}
</style>

<style lang='scss'>
div.el-dialog__wrapper.hide-modal ~ div.v-modal{
  display: none;
}
.v-modal:has(~ .hide-modal) {
  display: none;
}

</style>
