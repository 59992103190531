import AsyncValidator from 'async-validator';
import objectAssign from 'element-ui/src/utils/merge';
import { noop } from 'element-ui/src/utils/util';
import _ from 'lodash';
import { RESPONSIBLE_FLG, IP_REQUEST_TYPE, IPDN_CONVEYANCE, DELIVERY_TERMS, TRANSPORTATION_SERVICE_LEVEL, TRANSPORTATION_SERVICE_LEVEL_VARIABLES, CARGO_TRACKING_FLG } from 'lib-tw-common';

export default {
  methods: {
    // いずれかの取引先のNameとIdが必須のチェック
    checkOneOfCustomerId({rule, value, callback, names, errorMessage}) {
      const validItem = _.find(names, name => {
        return _.get(this.cloneItems, name) && _.get(this.cloneItems, name.replace(/Name$/, 'Id'));
      });
      if (validItem) {
        // バリデーションOK
        if (!rule.bulk) {
          this.clearValidateField(_.reject(names, validItem));
        }
        callback();
      } else if (!_.some(names, name => {
        return _.get(this.cloneItems, name);
      })) {
        // 全ての項目の入力がない場合
        callback(new Error(errorMessage));
      } else {
        if (!rule.bulk) {
          _.forEach(names, name => {
            if (!_.get(this.cloneItems, name)) {
              this.clearValidateField(name);
            }
          })
        }
        if (value && !_.get(this.cloneItems, rule.field.replace(/Name$/, 'Id'))) {
          callback(new Error('取引先マスタから選択してください'));
        } else {
          callback();
        }
      }
    },
    checkOneOfItem({rule, callback, names, errorMessage}) {
      if (_.some(names, name => {
        return _.get(this.cloneItems, name);
      })) {
        // バリデーションOK
        if (!rule.bulk) {
          this.clearValidateField(names);
        }
        callback();
      } else {
        // 全ての項目の入力がない場合
        callback(new Error(errorMessage));
      }
    },
    // 個別バリデーション
    tsValidation(rule, value, callback) {
      const itemSchema = this.getItemSchema(rule.field);
      const matchAry = rule.field.match(/\[\d+\]/);
      let unitTarget = itemSchema.unitTarget;

      // console.log(rule, value, callback)
      if (rule.tsValidationId === 'ts-rejectContainerType' && _.isString(value) && value.startsWith('R')) {
        // containerTypeのReefer項目は選択させない
        callback(new Error('現在、リーファーコンテナのBookingは出来ません。他のコンテナタイプを選択してください'));
        // 翻訳 Reefer Container Booking is currently limited service. Please select other container type.
      } else if (rule.tsValidationId === 'ts-isNotEmptyCustomerId' && value && !_.get(this.cloneItems, rule.field.replace(/Name$/, 'Id'))) {
        // 取引先名が合って取引先IDがない場合
        callback(new Error('取引先マスタから選択してください'));
      } else if (rule.tsValidationId === 'ts-isNotEmpty') {
        if (!value) {
          callback(new Error(`${itemSchema.label}は入力必須です`));
        } else {
          callback();
        }
      } else if (rule.tsValidationId === 'ts-isNotEmptyPlaceId') {
        // 港湾地名が自由入力の場合
        if (matchAry) {
          // 商品か連番項目の場合
          unitTarget = itemSchema.unitTarget.replace('.', matchAry[0] + '.');
        }
        if (!itemSchema || !_.get(this.cloneItems, unitTarget)) {
          callback(new Error('プルダウンから選択してください'));
        } else {
          callback();
        }
      } else if (rule.tsValidationId === 'ts-isNotEmptyUnitId') {
        // 単位が自由入力の場合
        if (matchAry) {
          // 商品か連番項目の場合
          unitTarget = itemSchema.unitTarget.replace('.', matchAry[0] + '.');
        }
        if (!itemSchema || !_.get(this.cloneItems, unitTarget)) {
          callback(new Error('プルダウンから選択してください'));
        } else {
          callback();
        }
      } else if (rule.tsValidationId === 'ts-isNotEmptyUnitAndUnitId') {
        // 単位が自由入力の場合
        if (matchAry) {
          // 商品か連番項目の場合
          const unitKey = _.last(itemSchema.unitTarget.split('.'));
          unitTarget = [..._.dropRight(rule.field.split('.')), unitKey].join('.');
        }
        const unit = _.get(this.cloneItems, unitTarget);
        const unitSchema = this.getItemSchema(itemSchema.unitTarget);
        let idTarget = unitSchema.unitTarget;
        if (matchAry && idTarget) {
          // 商品か連番項目の場合
          const idKey = _.last(unitSchema.unitTarget.split('.'));
          idTarget = [..._.dropRight(rule.field.split('.')), idKey].join('.');
        }
        if (!value) {
          callback();
        } else if (!unit) {
          callback(new Error(`${unitSchema.label}は入力必須です`));
        } else if (idTarget && !_.get(this.cloneItems, idTarget)) {
          callback(new Error('プルダウンから選択してください'));
        } else {
          callback();
        }
      } else if (rule.tsValidationId === 'ts-isNotEmptyCarrierIdOrScac') {
        // Carrier Name 及び SCAC のどちらか一方は必須入力
        if (_.get(this.cloneItems, 'linkageTransportation.carrierGrp.carrierName') || _.get(this.cloneItems, 'linkageTransportation.carrierGrp.carrierScac')) {
          if (rule.field.endsWith('carrierName')) {
            if (value && !_.get(this.cloneItems, 'linkageTransportation.carrierGrp.carrierId') && !_.get(this.cloneItems, 'linkageTransportation.carrierGrp.carrierScac')) {
              if (!rule.bulk) {
                this.clearValidateField('linkageTransportation.carrierGrp.carrierScac')
              }
              callback(new Error('取引先マスタから選択してください'));
            } else {
              if (!rule.bulk) {
                this.clearValidateField('linkageTransportation.carrierGrp.carrierScac')
              }
              callback();
            }
          } else {
            if (!rule.bulk) {
              this.clearValidateField('linkageTransportation.carrierGrp.carrierName');
            }
            callback();
          }
        } else {
          callback(new Error('Carrier Name及びSCAC のどちらか一方は必須入力です'));
        }
      } else if (rule.tsValidationId === 'ts-isNotEmptyShipperIdOrForwarderBookingId') {
        // Shipper Name 及び FWD(BKG) Name のどちらか一方は必須入力
        const names = ['linkageTransportation.shipperGrp.shipperName', 'linkageTransportation.fwdBkgGrp.forwarderBookingName'];
        const errorMessage = 'Shipper Name及びFWD(BKG) Nameのどちらか一方は必須入力です';
        this.checkOneOfCustomerId({rule, value, callback, names, errorMessage});
      } else if (rule.tsValidationId === 'ts-isNotEmptyNameOfVesselOrVoyageNoOrEtdOrDeliveryDate') {
        // Vessel Name 及び Voyage No. 及び Loading Port ETD 及び Delivery Date のいずれかは必須入力
        const names = ['linkageTransportation.logisticsLoadingGrp.nameOfVessel', 'linkageTransportation.logisticsLoadingGrp.voyageNo', 'linkageTransportation.logisticsLoadingGrp.loadingPortEtd', 'linkageTransportation.logisticsDeliveryGrp.deliveryDate'];
        const errorMessage = 'Vessel Name及びVoyage No.及びLoading Port ETD及びDelivery Dateのいずれかは必須入力です';
        this.checkOneOfItem({rule, callback, names, errorMessage});
      } else if (rule.tsValidationId === 'ts-isNotEmptyNameOfIpreqVesselWithMainConveyanceVessel') {
        // IP発行依頼でMain ConveyanceのVesselが選択されている場合はipreqVesselNameが必須
        const ary = rule.field.replace(/\[\d+\]/, '').split('.');
        const mainConveyance = _.get(this, `cloneItems.${ary[0]}.${ary[1]}${matchAry[0]}.${ary[2]}.mainConveyance`);
        if(!value && mainConveyance === IPDN_CONVEYANCE.VESSEL) {
          callback(new Error('Main ConveyanceがVesselの場合、入力必須です'));
        }  else {
          this.clearValidateField(`processSeparate.ipreqList[${matchAry[0]}].ipreqInformationGrp.ipreqVesselName`);
          callback();
        }
      } else if (rule.tsValidationId === 'ts-actionTypeIsNotEmpty') {
        // IP発行依頼個別 Action Type が訂正、取消の時必須入力
        const ary = rule.field.replace(/\[\d+\]/, '').split('.');
        const actionType = _.get(this, `cloneItems.${ary[0]}.${ary[1]}${matchAry[0]}.${ary[2]}.actionType`);
        if (!value && (actionType === IP_REQUEST_TYPE.AMEND || actionType === IP_REQUEST_TYPE.CANCEL)) {
          callback(new Error('ActionがAmendまたはCancelの場合、入力必須です'));
        } else {
          callback();
        }
      } else if (rule.tsValidationId === 'ts-freightChargesIsNotEmpty') {
        // IP発行依頼個別 貿易条件が「FOB」「EXW」「FCA」「FAS」のとき必須
        const ary = rule.field.replace(/\[\d+\]/, '').split('.');
        const incoterms = _.get(this, `cloneItems.${ary[0]}.${ary[1]}${matchAry[0]}.${ary[2]}.incoterms`);
        const isIncoterms = incoterms === DELIVERY_TERMS.FOB || incoterms === DELIVERY_TERMS.EXW || incoterms === DELIVERY_TERMS.FCA || incoterms === DELIVERY_TERMS.FAS;
        if (itemSchema.unitTarget) {
          // quantityやcurrency等の単位がある場合は、単位のチェックも行う
          let unitValue = _.get(this.cloneItems, itemSchema.unitTarget);
          if (matchAry) {
            let targetArray = rule.field.split('.');
            const unitArray = itemSchema.unitTarget.split('.');
            targetArray[targetArray.length - 1] = unitArray[unitArray.length - 1];
            unitValue = _.get(this.cloneItems, targetArray.join('.'));
          }
          if (isIncoterms && !(value && unitValue)) {
            callback(new Error('IncotermsがFOB, EXW, FCA, FASの場合、入力必須です'));
          } else {
            callback();
          }
        } else {
          if (isIncoterms && !value) {
            callback(new Error('IncotermsがFOB, EXW, FCA, FASの場合、入力必須です'));
          } else {
            callback();
          }
        }
      } else if (['ts-isNotEmptyInvoiceAmountOrIpreqCargoAmount', 'ts-unitRelationIsNotEmpty'].includes(rule.tsValidationId)) {
        const ary = rule.field.replace(/\[\d+\]/, '').split('.');
        const name = `${ary[0]}.${ary[1]}[${matchAry[0]}].${ary[2]}`;
        const fieldName = ary[ary.length - 1]
        const relatedFieldName = fieldName === 'ipreqCargoAmountInsured' ? 'ipInvoiceAmount' : 'ipreqCargoAmountInsured'
        const relatedFieldVal = _.get(this.cloneItems, `${name}.${relatedFieldName}`);
        let clearable = fieldName === 'ipInvoiceAmount';

        // Cargo Amount(金額)が入力されている場合は通貨は必須
        if(fieldName === 'ipreqCargoAmountInsured') {
          const unit = _.get(this.cloneItems, `${name}.ipreqCargoAmountInsuredCurrencyCode`);
          if (value && !unit) {
            callback(new Error('IPREQ Cargo Amount Insuredが入力されている場合、通貨は必須です'));
            return;
          } else {
            clearable = true;
          }
        }

        // 「Invoice金額」・「貨物保険金額」のいずれかは必須（ただし両方に入力がある場合もエラーとする）
        if (value && relatedFieldVal) {
          callback(new Error('Insurance Policy Invoice Amount及び IPREQ Cargo Amount Insuredのどちらかのみ入力してください'));
        } else if (!value && !relatedFieldVal) {
          callback(new Error('Insurance Policy Invoice Amount及び IPREQ Cargo Amount Insuredのいずれかは、入力必須です'))
        } else if(clearable) {
          this.clearValidateField(`${name}.${fieldName}`);
          callback();
        }
      }  else if (rule.tsValidationId === 'ts-relationIsNotEmpty') {
        // targetが入力されている場合入力必須
        let target = _.get(_.find(_.get(itemSchema, 'target.tsRelations', []), {validationId: 'ts-relationIsNotEmpty'}), 'target', '');
        if (matchAry) {
          // 対象が連番項目の場合 同じ連番項目のtargetに整形
          target = target.replace('[{n}]', `${matchAry[0]}`);
        }
        const targetSchema = this.getItemSchema(target);
        const targetValue = _.get(this.cloneItems, target);
        if ((itemSchema.inputType === 'quantity' || itemSchema.inputType === 'currency') && itemSchema.unitTarget) {
          // quantityやcurrency等の単位がある場合は、単位のチェックも行う
          let unitValue = _.get(this.cloneItems, itemSchema.unitTarget);
          if (matchAry) {
            let targetArray = target.split('.');
            const unitArray = itemSchema.unitTarget.split('.');
            targetArray[targetArray.length - 1] = unitArray[unitArray.length - 1];
            unitValue = _.get(this.cloneItems, targetArray.join('.'));
          }
          if (targetValue && !(value && unitValue)) {
            callback(new Error(`${_.get(targetSchema, 'label', '')}が入力されている場合、${itemSchema.label}は入力必須です`));
          } else {
            callback();
          }
        } else {
          if (targetValue && !value) {
            if (rule.field.endsWith('transportationServiceLevelOther')) {
              if(targetValue === TRANSPORTATION_SERVICE_LEVEL.OTHER) {
                callback(new Error(
                  `${_.get(targetSchema, 'label', '')}で${TRANSPORTATION_SERVICE_LEVEL_VARIABLES.find(
                    item => item.id === TRANSPORTATION_SERVICE_LEVEL.OTHER
                  ).label}が選択されている場合、${itemSchema.label}は入力必須です`
                ));
              } else {
                callback();
              }
            } else {
              callback(new Error(`${_.get(targetSchema, 'label', '')}が入力されている場合、${itemSchema.label}は入力必須です`));
            }
          } else {
            callback();
          }
        }
      } else if (rule.tsValidationId === 'ts-isNotEmptyAndUnit') {
        // 数値、単位の両方入力必須
        let unitValue = _.get(this.cloneItems, itemSchema.unitTarget);
        if (matchAry) {
          let targetArray = rule.field.split('.');
          const unitArray = itemSchema.unitTarget.split('.');
          targetArray[targetArray.length - 1] = unitArray[unitArray.length - 1];
          unitValue = _.get(this.cloneItems, targetArray.join('.'));
        }
        if (!(value && unitValue)) {
          callback(new Error(`${_.get(itemSchema, 'label', '')}は入力必須です`));
        } else {
          callback();
        }
      // PB-1105対応、輸出船積依頼プロセス
      } else if (rule.tsValidationId === 'ts-isRequiredIfExCargoTrackingFlgIsOnCondition1') {
        const exCargoTrackingFlg = _.get(this.cloneItems, 'processSeparate.cargoTrackingGrp.exCargoTrackingFlg');
        const carrierName = _.get(this.cloneItems, 'linkageTransportation.carrierGrp.carrierName');
        if (exCargoTrackingFlg === CARGO_TRACKING_FLG.ON && !carrierName) {
          callback(new Error('Cargo Tracking がONの場合、Carrier Name は入力必須です'));
        } else {
          callback();
        }
      // PB-1105対応、輸出船積依頼プロセス
      } else if (rule.tsValidationId === 'ts-isRequiredIfExCargoTrackingFlgIsOnCondition2') {
        // containerNo項目について、複数のうち最初の項目のみ入力を必須とする
        const isContainerNoField = rule.field === `linkageTransportation.containerInfoGrp.arrayContainerSeals${matchAry}.containerNo`;
        if (isContainerNoField && !(`${matchAry}` === '[0]')) {
          callback();
        }
        const exCargoTrackingFlg = _.get(this.cloneItems, 'processSeparate.cargoTrackingGrp.exCargoTrackingFlg');
        const vesselBookingNo = _.get(this.cloneItems, 'linkageTransportation.containerGrp.vesselBookingNo');
        const blNo = _.get(this.cloneItems, 'linkageTransportation.blGrp.blNo');
        const containerNo1 = _.get(this.cloneItems, 'linkageTransportation.containerInfoGrp.arrayContainerSeals[0].containerNo');

        if (exCargoTrackingFlg === CARGO_TRACKING_FLG.ON && !(vesselBookingNo || blNo || containerNo1)) {
          callback(new Error('Cargo Tracking がONの場合、Vessel Booking No. または B/L No. または Container No.は入力必須です'));
        } else {
          callback();
        }
      // PB-1140(PB-1061)対応、輸入荷捌依頼プロセス
      } else if (rule.tsValidationId === 'ts-isRequiredIfImCargoTrackingFlgIsOnCondition1') {
        const imCargoTrackingFlg = _.get(this.cloneItems, 'processSeparate.cargoTrackingGrp.imCargoTrackingFlg');
        const carrierName = _.get(this.cloneItems, 'linkageTransportation.carrierGrp.carrierName');
        if (imCargoTrackingFlg === CARGO_TRACKING_FLG.ON && !carrierName) {
          callback(new Error('Cargo Tracking がONの場合、Carrier Name は入力必須です'));
        } else {
          callback();
        }
      // PB-1140(PB-1061)対応、輸入荷捌依頼プロセス
      } else if (rule.tsValidationId === 'ts-isRequiredIfImCargoTrackingFlgIsOnCondition2') {
        // containerNo項目について、複数のうち最初の項目のみ入力を必須とする
        const isContainerNoField = rule.field === `linkageTransportation.containerInfoGrp.arrayContainerSeals${matchAry}.containerNo`;
        if (isContainerNoField && !(`${matchAry}` === '[0]')) {
          callback();
        }
        const imCargoTrackingFlg = _.get(this.cloneItems, 'processSeparate.cargoTrackingGrp.imCargoTrackingFlg');
        const vesselBookingNo = _.get(this.cloneItems, 'linkageTransportation.containerGrp.vesselBookingNo');
        const blNo = _.get(this.cloneItems, 'linkageTransportation.blGrp.blNo');
        const containerNo1 = _.get(this.cloneItems, 'linkageTransportation.containerInfoGrp.arrayContainerSeals[0].containerNo');
        if (imCargoTrackingFlg === CARGO_TRACKING_FLG.ON && !(vesselBookingNo || blNo || containerNo1)) {
          callback(new Error('Cargo Tracking がONの場合、Vessel Booking No. または B/L No. または Container No.は入力必須です'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // forked from https://github.com/ElemeFE/element/blob/dev/packages/form/src/form-item.vue
    tsValidateField(field, trigger, callback = noop) {
      if (field.validateMessage) {
        callback();
        return true;
      }

      field.validateDisabled = false;
      const rules = field.getRules().filter(rule => {
        if (!rule.tsValidationId) return false;
        const isDraftValid = _.get(this, 'draftValid', false);
        const isNotEmpty = _.some(['isNotEmpty', 'IsNotEmpty'], id => rule.tsValidationId.includes(id));
        // 下書き保存の場合は必須系のバリデーションは除外
        // NOTE: Ph7時点 下記の項目は下書き保存時でもサーバ側の仕様に合わせてチェックする
        // IP発行依頼個別プロセス ipreqQuantity1_1~10の必須相関チェック
        // transportationServiceLevelOtherの必須相関チェック
        // ph9時点 Invoice金額と保険金額の相間チェック
        if (isDraftValid && 
            (!isNotEmpty || 
              !(
                field.prop.includes('ipreqQuantity1_') ||
                field.prop.endsWith('transportationServiceLevelOther') ||
                field.prop.endsWith('ipreqCargoAmountInsured') ||
                field.prop.endsWith('ipInvoiceAmount')
              )
            ) &&
            // PB-1105 輸出船積依頼プロセスの対応として、下記項目（"!({括弧内})"の中に記載されているfield.prop）は、下書き保存の際もバリデーションチェックを行う
            !(
              (rule.tsValidationId === 'ts-isRequiredIfExCargoTrackingFlgIsOnCondition1' && field.prop === 'linkageTransportation.carrierGrp.carrierName') ||
              (rule.tsValidationId === 'ts-isRequiredIfExCargoTrackingFlgIsOnCondition2'
                && (
                  field.prop === 'linkageTransportation.containerGrp.vesselBookingNo' ||
                  field.prop === 'linkageTransportation.blGrp.blNo' ||
                  field.prop === 'linkageTransportation.containerInfoGrp.arrayContainerSeals[0].containerNo'
                )
              )
            ) &&
            // PB-1140(PB-1061) 輸入荷捌依頼プロセスの対応として、下記項目（"!({括弧内})"の中に記載されているfield.prop）は、下書き保存の際もバリデーションチェックを行う
            !(
              (rule.tsValidationId === 'ts-isRequiredIfImCargoTrackingFlgIsOnCondition1' && field.prop === 'linkageTransportation.carrierGrp.carrierName') ||
              (rule.tsValidationId === 'ts-isRequiredIfImCargoTrackingFlgIsOnCondition2'
                && (
                  field.prop === 'linkageTransportation.containerGrp.vesselBookingNo' ||
                  field.prop === 'linkageTransportation.blGrp.blNo' ||
                  field.prop === 'linkageTransportation.containerInfoGrp.arrayContainerSeals[0].containerNo'
                )
              )
            )
        ) {
          // 上記ifに該当すればバリデーションしない
          return false;
        }
        if (!rule.trigger || trigger === '') return true;
        if (Array.isArray(rule.trigger)) {
          return rule.trigger.indexOf(trigger) > -1;
        } else {
          return rule.trigger === trigger;
        }
      }).map(rule => objectAssign({}, rule));

      if ((!rules || rules.length === 0) && field.required === undefined) {
        callback();
        return true;
      }
      field.validateState = 'validating';
      const descriptor = {};
      if (rules && rules.length > 0) {
        rules.forEach(rule => {
          delete rule.trigger;
          // 一括チェックフラグ
          rule.bulk = true;
        });
      }
      descriptor[field.prop] = rules;
      const validator = new AsyncValidator(descriptor);
      const model = {};
      model[field.prop] = field.fieldValue;
      validator.validate(model, { firstFields: true }, (errors, invalidFields) => {
        field.validateState = !errors ? 'success' : 'error';
        field.validateMessage = errors ? errors[0].message : '';
        callback(field.validateMessage, invalidFields);
        field.elForm && field.elForm.$emit('validate', field.prop, !errors, field.validateMessage || null);
      });
    },
    // forked from https://github.com/ElemeFE/element/blob/dev/packages/form/src/form.vue
    clearValidateField(props, message) {
      props = [].concat(props);
      const fields = this.$refs.form.fields.filter(field => props.indexOf(field.prop) !== -1);
      if (!fields.length) {
        console.warn('[Element Warn]please pass correct props!');
        return;
      }
      fields.forEach(field => {
        if (!message || message === field.validateMessage) {
          field.clearValidate();
        }
      });
    },
    // forked from https://github.com/ElemeFE/element/blob/dev/packages/form/src/form.vue
    tsValidationAll(ref, callback) {
      let promise;
      // if no callback, return promise
      if (typeof callback !== 'function' && window.Promise) {
        promise = new window.Promise((resolve, reject) => {
          callback = function(valid, invalidFields) {
            valid ? resolve(valid) : reject(invalidFields);
          };
        });
      }
      let valid = true;
      let count = 0;

      if (ref.fields.length === 0 && callback) {
        callback(true);
      }
      let invalidFields = {};
      ref.fields.forEach(field => {
        this.tsValidateField(field, '', (message, field) => {
          if (message) {
            valid = false;
          }
          invalidFields = objectAssign({}, invalidFields, field);
          if (typeof callback === 'function' && ++count === ref.fields.length) {
            callback(valid, invalidFields);
          }
        });
      });
      if (promise) {
        return promise;
      }
    },
    // 一括バリデーション呼び出し
    async tsValidationCheck(ref) {
      await this.$nextTick();
      return new Promise(resolve => {
        this.tsValidationAll(ref)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
      });
    },
    // formに依存しないts-validation
    // 配列項目のバリデーションオール
    async tsValidationAllArray(name, schema) {
      // targetArray: バリデーション対象配列
      // IP発行依頼の対象配列は、'processSeparate.ipreqList'で受け取る
      // 商品等はテーブル直下に配列があるので、対象配列はtableNameで受け取る
      const tableName = name.split('.')[0];
      const arrayName = _.get(name.split('.'), '[1]');
      const targetName = tableName + (arrayName ? `.${arrayName}` : '');
      const targetArray = _.get(this.cloneItems, targetName);
      if (!(targetArray && targetArray.length)) {
        return;
      }

      // 対象配列が属するテーブルスキーマから、tsValidationId, groupName, itemNameを抽出
      // tsValidationIdがある項目のみ、非表示項目も除外、FromToに丸がついていない項目も除外
      let targetRules = [];
      await _.forEach(schema.groups, group => {
        const groupName = group.variableName;
        _.forEach(group.children, item => {
          const itemName = item.key;
          if (item.tsValidationId && !item.hide && item[this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to']) {
            _.forEach(item.tsValidationId, validationId => {
              const isDraftValid = _.get(this, 'draftValid', false);
              const isNotEmpty = _.some(['isNotEmpty', 'IsNotEmpty'], id => validationId.includes(id));
              // 下書き保存の場合は必須系のバリデーションを追加しない
              // NOTE: IP発行依頼個別プロセス (Ph7時点)ipreqQuantity1_1~10、(ph9時点)「Invoice金額と貨物保険金額」の必須相関チェックは、下書き保存時でもサーバ側の仕様に合わせてチェックする
              if(!isDraftValid || (isDraftValid && !isNotEmpty) || (itemName.includes('ipreqQuantity1_') || ['ipInvoiceAmount', 'ipreqCargoAmountInsured'].includes(itemName))) {
                targetRules.push({
                  validationTargetGroup: groupName,
                  validationTargetItem: itemName,
                  tsValidationId: validationId,
                });
              }
            });
          }
        });
      });

      // validationチェック処理
      if (targetRules.length) {
        let arrayErrors = [];
        // 対象配列をループ
        _.forEach(targetArray, (line, lineIndex) => {
          // スキーマから抽出したルールでさらにループ
          _.forEach(targetRules, async targetRule => {
            // tsValidationに渡すパラメータを生成
            const field = `${targetName}[${lineIndex}].${targetRule.validationTargetGroup}.${targetRule.validationTargetItem}`;
            const value = _.get(line, `${targetRule.validationTargetGroup}.${targetRule.validationTargetItem}`);
            const rule = {
              field: field,
              tsValidationId: targetRule.tsValidationId,
            };
            const callback = errorMessage => {
              if (errorMessage) {
                // console.log(errorMessage);
                arrayErrors.push(field); // エラーがあったらarrayErrorsに追加しておく
              }
            };
            // tsValidation実行
            await this.tsValidation(rule, value, callback);
          });
        });

        // バリデーションリザルト処理
        if (arrayErrors.length) {
          // this.errorsにエラー項目を追加
          this.errors = _.uniq(this.errors.concat(arrayErrors));
          // console.log('invalid.')
          // console.log(this.errors)
          throw false; // エラーが1件でもあった場合は、rejectを返す
        }
        // NOTE: tsArrayValidationの前に、lk-validationとtsValidationAllを通って、this.errorsに値が入っている可能性があるため、
        // tsArrayValidationで全てvalid: trueだった場合でも、this.errorsを空にしない
        // console.log('valid!')
        return;
      } else {
        return;
      }
    },
    // 一括呼び出し
    async tsValidationAllArrayCheck(name, schema) {
      await this.$nextTick();
      return new Promise(resolve => {
        this.tsValidationAllArray(name, schema)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
      });
    },

  }
};
