<template>
  <div class="process_detail_inner">
    <div class="head">
      <div class='title_wrapper'>
        <div class='title_inner'>
          <tw-entity-icon :entityName="getParent" size="large" />
          <div class="title">{{processName}}</div>
          <tw-status-icon v-if="typeof tradeManagement.processProgressStatus === 'number'"
                          :statusCode="tradeManagement.processProgressStatus"
                          :approval="!!approvalProcess"
          />
        </div>
        <div v-if="tradeManagement.processUserMemo" class='process_memo'>
          {{tradeManagement.processUserMemo}}
        </div>
      </div>
      <div>
        <tw-from-to :from="from" :to="to" />
      </div>
    </div>

    <!-- 入力項目にない相関チェック項目 -->
    <template v-if="localValue.other">
      <el-form-item v-show="false" label="Contract Date" prop="other.contractDate">
      <el-input type="hidden" v-model="localValue.other.contractDate" />
      </el-form-item>
      <el-form-item v-show="false" label="L/C Latest Nego Date" prop="other.lcLatestNegoDate">
        <el-input type="hidden" v-model="localValue.other.lcLatestNegoDate" />
      </el-form-item>
      <el-form-item v-show="false" label="Customer L/C Latest Shipment Date" prop="other.customerLCLatestShipmentDate">
        <el-input type="hidden" v-model="localValue.other.customerLCLatestShipmentDate" />
      </el-form-item>
      <el-form-item v-show="false" label="B/L Date" prop="other.blDate">
        <el-input type="hidden" v-model="localValue.other.blDate" />
      </el-form-item>
    </template>

    <!-- チェックボックスグループ -->
    <tw-check-boxes v-if="localValue.processSeparate && (processType === PROCESS_TYPE.ECREQ || processType === PROCESS_TYPE.ICREQ)" :isCheckBox="isFromPic()" v-model="checkValue" :items="isFromPic() ? checkItems : checkValue" head="Types of Work" @input="onChecked" />

    <div class="sub_buttons">
      <div class="column">
        <!-- トグルボタン -->
        <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
          <img src="@/assets/images/icons/view_all.svg">
        </tw-button>
        <!-- 許可書紐づけボタン -->
        <tw-button v-if="(processType === PROCESS_TYPE.ECPERMIT || processType === PROCESS_TYPE.ICPERMIT)" type="secondary" icon="chain" size="small" :disabled="!isFromPic()" @click="showPermitDialog">Select {{ processType === PROCESS_TYPE.ECPERMIT ? 'Export' : 'Import' }} Permit</tw-button>
        <!-- I/P, D/N紐づけボタン -->
        <tw-button v-if="processType === PROCESS_TYPE.MIIP" type="secondary" icon="chain" size="small" :disabled="!isFromPic()" @click="showIpDnDialog">Select I/P.D/N</tw-button>
        <!-- L/C紐づけボタン -->
        <tw-button v-if="processType === PROCESS_TYPE.LCACCEPT" type="secondary" icon="chain" size="small" :disabled="!isFromPic()" @click="showLcDialog">Select L/C</tw-button>
      </div>

      <!-- グラフィカルボタン -->
      <tw-button v-if="needGraphical" type="secondary" icon="graphical" size="small" @click="showGraphical">Graphical Mode</tw-button>
    </div>

    <!-- 許可書情報 -->
    <TwPermitLinking v-if="localValue.permitLatest && localValue.permitLatest.declarationNo" :permitLatest="localValue.permitLatest" :isFromPic="isFromPic()" @remove-permit="removePermit" :processType="processType" />
    <!-- I/P, D/N情報 -->
    <TwIpDnLinking v-if="localValue.ipDnLatest && localValue.ipDnLatest.length" :ipDnLatest="localValue.ipDnLatest" :isFromPic="isFromPic()" @remove-ip-dn="removeIpDn" />
    <!-- L/C情報 -->
    <TwLcLinking v-if="localValue.lcLatest && localValue.lcLatest.length" :lcLatest="localValue.lcLatest" :isFromPic="isFromPic()" @remove-lc="removeLc" />
    <!-- Booking情報 -->
    <TwBookingLinking v-if="localValue.bookingLatest" :bookingCancelFlg="localValue.bookingCancelFlg" @onCheckedBookingCancelFlg="onCheckedBookingCancelFlg" :linkageTransportation="localValue.linkageTransportation" :bookingLatest="localValue.bookingLatest" :isFromPic="isFromPic()" />

    <!-- IP発行依頼個別 -->
    <table v-if="processType === PROCESS_TYPE.MIRIP" class="card last">
      <TwIpRequestProcessList v-for="table in IpRequestSchema" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :initial-open="true" />
    </table>
    <!-- Invoice No等の情報群 必須項目を含む情報群 -->
    <table v-if="requiredGroup.length" class="card separate" :class="{last: !transferTermsGroup.length && !documentSignerGroup.length}">
      <tw-process-list v-for="table in requiredGroup" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :fixed-open="true" :requiredGroup="true" />
    </table>
    <!-- Transfer Term情報群 -->
    <table v-if="transferTermsGroup.length" class="card separate" :class="{last: !documentSignerGroup.length}">
      <tw-process-list v-for="table in transferTermsGroup" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :fixed-open="true" />
    </table>

    <!-- Document Signer -->
    <table v-if="documentSignerGroup.length" class="card separate last document_signer">
      <tw-process-list v-for="table in documentSignerGroup" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :fixed-open="true" />
    </table>

    <!-- タブ -->
    <div ref="tabs" class="tabs" style="width: 100%">
      <div class="tab" v-if="schemaTab1.length" :class="{active: activeTab === 0, has_error: tabError[0]}" @click="switchTab(0)">Goods</div>
      <div class="tab" v-if="schemaTab4.length" :class="{active: activeTab === 3, has_error: tabError[3]}" @click="switchTab(3)">Information</div>
      <div class="tab" v-if="schemaTab2.length" :class="{active: activeTab === 1, has_error: tabError[1]}" @click="switchTab(1)">Players</div>
      <div class="tab" v-if="schemaTab3.length" :class="{active: activeTab === 2, has_error: tabError[2]}" @click="switchTab(2)">Logistics</div>
      <div class="tab" v-if="hasLcdata" :class="{active: activeTab === 5, has_error: tabError[5]}" @click="switchTab(5)">L/C</div>
      <div class="tab" v-if="schemaTab5.length" :class="{active: activeTab === 4, has_error: tabError[4]}" @click="switchTab(4)">Undisclosed</div>
    </div>

    <!-- タブコンテンツ -->
    <table id="tab0" v-show="activeTab === 0" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
      <tw-process-list v-for="table in schemaTab1" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :tab="0" />
    </table>
    <table id="tab3" v-show="activeTab === 3" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
      <tw-process-list v-for="table in schemaTab4" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :initSelectIp="true" :initSelectDn="true" :tab="3" />
    </table>
    <table id="tab1" v-show="activeTab === 1" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
      <tw-process-list v-for="table in schemaTab2" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :visibleIp="false" :tab="1" />
    </table>
    <table id="tab2" v-show="activeTab === 2" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
      <!-- Vessel Name -->
      <tr v-if="vesselName" id="vessel_name" class="vessel_name" :class="{has_error: vesselNameContentHasError}">
        <td colspan="3">
          <tw-process-group-list-edit
            :item="vesselName.item"
            :tableName="vesselName.tableName"
            :groupName="vesselName.groupName"
            :serialIndexes="{}"
            :serialItems="{}"
            v-model="localValue"
            style="width: 924px"
          />
          <div style="display: flex">
            <tw-process-group-list-edit
              :item="voyageNo.item"
              :tableName="voyageNo.tableName"
              :groupName="voyageNo.groupName"
              :serialIndexes="{}"
              :serialItems="{}"
              v-model="localValue"
            />
            <tw-process-group-list-edit
              v-if="s.checkTransportation('linkageTransportation.containerGrp')"
              :item="vesselBookingNo.item"
              :tableName="vesselBookingNo.tableName"
              :groupName="vesselBookingNo.groupName"
              :serialIndexes="{}"
              :serialItems="{}"
              v-model="localValue"
            />
          </div>
        </td>
      </tr>
      <tw-process-list v-for="table in schemaTab3" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" ref="tab2" :tab="2" />
    </table>
    <table id="tab5" v-show="activeTab === 5" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
      <TwLcPoolProcessList v-if="hasLcdata" :isEditMode="true" v-model="localValue" :initialOpen="true" :initSelectLc="true" :isProcess="true" :tab="5" />
    </table>
    <table id="tab4" v-show="activeTab === 4" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
      <tw-process-list v-for="table in schemaTab5" :table="table" :key="table.name" :isEditMode="true" v-model="localValue" :initSelectIp="true" :initSelectDn="true" :tab="4" />
    </table>

    <!-- 添付ファイル -->
    <div class="attachment" @dragover.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile" :class="{enter: dragin}">
      <div class="attach">
        <div class="head">Attach File</div>
        <!-- 許可書修正・訂正許可通知チェック -->
        <div v-if="processType === PROCESS_TYPE.ECPERMIT || processType === PROCESS_TYPE.ICPERMIT" class="amendment_flg">
          <el-checkbox border class="border_none" v-model="amendmentFlg" :true-label="AMENDMENT_FLG.ON" :false-label="AMENDMENT_FLG.OFF">Amended Customs Permit</el-checkbox>
        </div>
        <div>
          <tw-button type="secondary" size="medium" class="choose_file" @click="loadFile">Choose Local File<input ref="fileInput" class="file_input" @change="setFile" type="file" multiple :accept="'.' + WHITE_LIST.join(',.')"></tw-button>
          <tw-button v-if="localValue.tradingDocuments" type="secondary" icon="plus" size="medium" @click="showDocumentsDialog">Select</tw-button>
        </div>
      </div>
      <el-form :model="documentsForm" ref="documentsForm" @validate="onValidate">
        <template v-for="(doc, index) in documentsForm.attachedDocuments">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <div v-if="doc.documentEditFlg !== DOCUMENT_EDIT_FLG.DELETE" class="file">
            <div class="inner">
              <div class="user">
                <figure class="avatar table"><img :src="doc.updateUserIcon"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{doc.updateUserName}}</div>
                  <div class="from_name">{{doc.updateUserName}}</div>
                </el-tooltip>
              </div>
              <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{doc.documentName}}</div>
                <span>
                  <a class="file_name" @click.prevent="download(doc)" tabindex="-1">{{doc.documentName}}</a>
                </span>
              </el-tooltip>
              <span class="meta">{{doc.updateDate | dateTimeFormat}},&nbsp;{{doc.documentSize | fileSize}}</span>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">PDF Preview</div>
                <img v-if="doc.documentEditFlg !== DOCUMENT_EDIT_FLG.DELETE && doc.documentPath && s.isPdf(doc)" @click="s.previewPdf(doc)" style="cursor:pointer;margin-left: 16px" src="@/assets/images/icons/icon_pdf_preview.svg">
              </el-tooltip>
              <div class=checkbox-group>
                <div>
                  <el-checkbox v-for="item in documentCheckbox" :key="item.id + '-' + index" v-model="doc['documentLink' + getDocumentKey(item.code)]" @change="updateUploadedDocumentsCategories(doc, item, index)">
                    <span>{{ item.label }}</span>
                  </el-checkbox>
                </div>
                <div class="others_checkbox">
                  <el-checkbox v-for="item in documentOthersCheckbox" :key="item.id + '-' + index" v-model="doc['documentLink' + getDocumentKey(item.code)]" @change="updateUploadedDocumentsCategories(doc, item, index)">
                    <span>{{ item.label }}</span>
                    <el-input v-model="doc['documentLinkLabel' + getDocumentKey(item.code)]" class="document_input" style="width: 120px;" :readonly="!doc['documentLink' + getDocumentKey(item.code)]" @change="onCommentChange(doc, index)" maxlength="30" show-word-limit/>
                  </el-checkbox>
                </div>
              </div>
            </div>
            <el-form-item :prop="`attachedDocuments[${index}].documentCommentText`" :rules="[{ max: 100, message: 'Free Textは100文字以内で入力してください', trigger: 'blur' }]">
              <el-select v-if="processType === PROCESS_TYPE.FDCOR" class="document_id" v-model="doc.documentCommentText" @change="onCommentChange(doc, index)" clearable>
                <el-option
                  v-for="code in CO_DOCUMENT_ID_VARIABLES"
                  :key="code.code"
                  :label="code.label"
                  :value="code.code">
                </el-option>
              </el-select>
              <el-input v-else type="textarea" :autosize="{ minRows: 1, maxRows: 6}" v-model="doc.documentCommentText" :disabled="doc.editableFlg !== EDITABLE_FLG.ON" @change="onCommentChange(doc, index)" maxlength="100" show-word-limit />
            </el-form-item>
            <img v-if="doc.editableFlg === EDITABLE_FLG.ON && checkAttachmentDeletable(doc.documentManageId)" class="close_times" src="@/assets/images/icons/times_black.svg" @click.stop.prevent="removeAttachedFile(doc, index)">
          </div>
        </template>
        <template v-for="(doc, index) in documentsForm.documents">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <div class="file">
            <div class="inner">
              <el-tooltip placement="top-start" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{doc.documentName}}</div>
                <span>
                  <a v-if="doc.documentValue" class="file_name" :href="doc.documentValue" :download="doc.documentName">{{doc.documentName}}</a>
                  <a v-else class="file_name" @click.prevent="download(doc)" tabindex="-1">{{doc.documentName}}</a>
                </span>
              </el-tooltip>
              <span class="meta">{{doc.updateDate | dateTimeFormat}},&nbsp;{{doc.documentSize | fileSize}}</span>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">PDF Preview</div>
                <img v-if="s.isPdf(doc)" @click="s.previewPdf(doc)" style="cursor:pointer;margin-left: 16px" src="@/assets/images/icons/icon_pdf_preview.svg">
              </el-tooltip>
              <div class=checkbox-group>
                <div>
                  <el-checkbox v-for="item in documentCheckbox" :key="item.id + '-' + index" v-model="doc['documentLink' + getDocumentKey(item.code)]" @change="updateUploadedDocumentsCategories(doc, item, index)">
                    <span>{{ item.label }}</span>
                  </el-checkbox>
                </div>
                <div class="others_checkbox">
                  <el-checkbox v-for="item in documentOthersCheckbox" :key="item.id + '-' + index" v-model="doc['documentLink' + getDocumentKey(item.code)]" @change="updateUploadedDocumentsCategories(doc, item, index)">
                    <span>{{ item.label }}</span>
                    <el-input v-model="doc['documentLinkLabel' + getDocumentKey(item.code)]" :readonly="!doc['documentLink' + getDocumentKey(item.code)]" class="document_input" style="width: 120px;" maxlength="30" show-word-limit />
                  </el-checkbox>
                </div>
              </div>
            </div>
            <el-form-item :prop="`documents[${index}].documentComment`" :rules="[{ max: 100, message: 'Free Textは100文字以内で入力してください', trigger: 'blur' }]">
              <el-select v-if="processType === PROCESS_TYPE.FDCOR" class="document_id" v-model="doc.documentComment" clearable>
                <el-option
                  v-for="code in CO_DOCUMENT_ID_VARIABLES"
                  :key="code.code"
                  :label="code.label"
                  :value="code.code">
                </el-option>
              </el-select>
              <el-input v-else type="textarea" :autosize="{ minRows: 1, maxRows: 6}" v-model="doc.documentComment" maxlength="100" show-word-limit />
            </el-form-item>
            <img class="close_times" src="@/assets/images/icons/times_black.svg" @click.stop.prevent="removeFile(index)">
          </div>
        </template>
      </el-form>

      <div class="droparea">
        <img src="@/assets/images/dropfile.svg">
        Drop files here to upload.
      </div>
    </div>
    <div class="buttons">
      <tw-button v-if="needDraftPdfButton" type="secondary" size="medium" @click="draftPdf">Request Draft</tw-button>
      <tw-button v-if="needSaveButton" type="secondary" size="medium" @click="save">Save</tw-button>
      <tw-button v-if="needApplyButton" type="primary" size="medium" @click="approvalRequest">Apply</tw-button>
      <tw-button v-if="needRegisterButton" type="primary" size="medium" @click="register">Register</tw-button>
    </div>

    <!-- グラフィカルモード -->
    <el-drawer :visible.sync="isShowGraphical" size="100%" :modal="false" :withHeader="false" @opened="setGraphical" @close="setGraphical">
      <TwGraphicalNetwork v-if="isShowGraphical" :schemas="schemas" @show-graphical="showGraphical" />
    </el-drawer>

    <!-- 同一取引内の帳票を選択 -->
    <el-dialog :title="$t('Label.Add Select File')" :visible.sync="dialogAddDocumentsVisible" :close-on-click-modal="false" :before-close="documentsDialogBeforeClose" class="header_border" width="980px" aria-label="Add Select File" :append-to-body="true">
      <TwAddDocuments v-if="dialogAddDocumentsVisible" ref="addDocumentsDialog" :items="localValue.tradingDocuments" :tradingName="tradeManagement.tradingManageName" :flowName="tradeManagement.tradingFlowName" @add-documents="addDocuments" @download="download" />
    </el-dialog>

    <!-- 許可書紐づけダイアログ -->
    <el-dialog :title="$t(`Label.Select ${processType === PROCESS_TYPE.ECPERMIT ? 'Export' : 'Import'} Permit`)" :visible.sync="dialogAddPermitVisible" :close-on-click-modal="false" class="header_border" width="1040px" aria-label="Select Permit" :append-to-body="true">
      <TwAddPermit v-if="dialogAddPermitVisible" :processType="processType" @add-permit="addPermit" :permit="localValue.permit" />
    </el-dialog>
    <!-- I/P, D/N紐づけダイアログ -->
    <el-dialog :title="$t('Label.Select I/P, D/N')" :visible.sync="dialogAddIpDnVisible" :close-on-click-modal="false" class="header_border" width="1040px" aria-label="Select I/P, D/N" :append-to-body="true">
      <TwAddIpDn v-if="dialogAddIpDnVisible" @add-ip-dn="addIpDn" :ipDn="localValue.ipDn" />
    </el-dialog>
    <!-- L/C紐づけダイアログ -->
    <el-dialog :title="$t('Label.Select L/C')" :visible.sync="dialogAddLcVisible" :close-on-click-modal="false" class="header_border" width="1200px" aria-label="Select L/C" :append-to-body="true">
      <TwAddLc v-if="dialogAddLcVisible" @add-lc="addLc" :lc="localValue.lc" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { RESPONSIBLE_FLG, PROCESS_OPERATION, TYPE_OF_WORKS, USER_ROLE_CD, PROCESS_TYPE, DOCUMENT_EDIT_FLG, EDITABLE_FLG, PROCESS_PROGRESS_STATUS, CO_DOCUMENT_ID_VARIABLES, ATTACH_FILE_EXTENSION_VARIABLES, ATTACH_FILE_EXTENSION_CO_VARIABLES, AMENDMENT_FLG, DOCUMENT_LINK_VARIABLES } from 'lib-tw-common';
import { processNames } from '@/dictionaries/map.js';
import { processTabType } from '@/dictionaries/processTabType.js';
import TwButton from '@/components/atoms/TwButton';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwStatusIcon from '@/components/atoms/TwStatusIcon';
import TwFromTo from '@/components/molecules/TwFromTo';
import TwProcessGroupListEdit from '@/components/molecules/TwProcessGroupListEdit';
import TwProcessList from '@/components/organisms/TwProcessList';
// import TwLcPoolProcessList from '@/components/organisms/TwLcPoolProcessList';
import TwCheckBoxes from '@/components/atoms/TwCheckBoxes';
// import TwGraphicalNetwork from '@/components/templates/TwGraphicalNetwork';
// import TwAddDocuments from '@/components/organisms/TwAddDocuments';
// import TwAddPermit from '@/components/organisms/TwAddPermit';
// import TwPermitLinking from '@/components/organisms/TwPermitLinking';
// import TwAddIpDn from '@/components/organisms/TwAddIpDn';
// import TwIpDnLinking from '@/components/organisms/TwIpDnLinking';
// import TwAddLc from '@/components/organisms/TwAddLc';
// import TwLcLinking from '@/components/organisms/TwLcLinking';
// import TwBookingLinking from '@/components/organisms/TwBookingLinking';
import { computed } from "@vue/composition-api";

export default {
  name: 'TwEditProcessInner',
  inject: ['s'],
  provide() {
    return {
      activeTab: computed(() => this.activeTab),
    }
  },
  props: {
    processId: String,
    processType: String,
    processName: String,
    schemas: Object,
    value: Object,
    from: Object,
    to: Object,
    roles: Object,
    tradeManagement: Object,
    approvalProcess: Boolean,
    errors: Array,
    isSubFlow: Boolean,
    parentGoodsLine: Array,
  },
  components: {
    TwButton,
    TwEntityIcon,
    TwStatusIcon,
    TwFromTo,
    TwProcessList,
    TwIpRequestProcessList: () => import('@/components/organisms/TwIpRequestProcessList'),
    TwLcPoolProcessList: () => import('@/components/organisms/TwLcPoolProcessList'),
    TwCheckBoxes,
    TwGraphicalNetwork: () => import('@/components/templates/TwGraphicalNetwork'),
    TwAddDocuments: () => import('@/components/organisms/TwAddDocuments'),
    TwAddPermit: () => import('@/components/organisms/TwAddPermit'),
    TwPermitLinking: () => import('@/components/organisms/TwPermitLinking'),
    TwAddIpDn: () => import('@/components/organisms/TwAddIpDn'),
    TwIpDnLinking: () => import('@/components/organisms/TwIpDnLinking'),
    TwAddLc: () => import('@/components/organisms/TwAddLc'),
    TwLcLinking: () => import('@/components/organisms/TwLcLinking'),
    TwBookingLinking: () => import('@/components/organisms/TwBookingLinking'),
    TwProcessGroupListEdit,
  },
  data() {
    return {
      documentsForm: {
        documents: [],
        attachedDocuments: _.clone(this.value.documents),
      },
      documentSigners: [
        'coApplicantId1',
        'coApplicantName1',
        'coSignerId1',
        'coSignerName1',
        'coSignerTitle1',
        'coInvoiceSignerId1',
        'coInvoiceSignerName1'
      ],
      dragin: false,
      WHITE_LIST: [],
      activeTab: 0,
      tabError: [
        false,
        false,
        false,
        false,
        false,
      ],
      vesselNameContentHasError: false,
      expand: true,
      checkItems: [
        { value: 'workTypeDocumentation', label: 'Documentation' },
        { value: 'workTypeCustomsClearance', label: 'Customs Clearance' },
        this.processId.startsWith('EC') ? { value: 'workTypeLoading', label: 'Loading' } : { value: 'workTypeDischarging', label: 'Discharging' },
        { value: 'workTypeBooking', label: 'Booking' },
        { value: 'workTypeMarineIp', label: 'Marine IP' }
      ],
      checkValue: [],
      isShowGraphical: false,
      dialogAddDocumentsVisible: false,
      dialogAddPermitVisible: false,
      dialogAddIpDnVisible: false,
      dialogAddLcVisible: false,
      vesselName: null,
      DOCUMENT_EDIT_FLG,
      EDITABLE_FLG,
      AMENDMENT_FLG,
      PROCESS_TYPE,
      CO_DOCUMENT_ID_VARIABLES,
      DOCUMENT_LINK_VARIABLES,
      pdfSrc: null,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    // 必須項目を含む情報群
    requiredGroup() {
      let table;
      if (this.processType === PROCESS_TYPE.COPO) {
        // PO送付プロセスの時
        table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageContract'}));
        if (!table) {
          return [];
        }
        table.groups = _.filter(table.groups, {variableName: 'poNoDateGrp'});
      } else if (this.processType === PROCESS_TYPE.COCOS) {
        // 契約締結プロセスの時
        table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageContract'}));
        if (!table) {
          return [];
        }
        table.groups = _.filter(table.groups, {variableName: 'contractNoDateGrp'});
      } else if (this.processType === PROCESS_TYPE.ECREQ) {
        // 輸出船積依頼プロセスの時
        table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageExportCustoms'}));
        if (!table) {
          return [];
        }
        table.groups = _.filter(table.groups, {variableName: 'exCustomClearanceGrp'});
      } else if (this.processType === PROCESS_TYPE.MIIP) {
        // I/P保管プロセスの時
        table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageMarineInsurance'}));
        if (!table) {
          return [];
        }
        table.groups = _.filter(table.groups, {variableName: 'insurancePolicyGrp'});
      } else if (this.processType === PROCESS_TYPE.FDDOC || this.processType === PROCESS_TYPE.FDCRTDOC) {
        // 船積書類送付プロセスの時
        table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageFinalDocs'}));
        if (!table) {
          return [];
        }
        table.groups = _.filter(table.groups, {variableName: 'commercialInvoiceGrp'});
      } else if (this.processType === PROCESS_TYPE.ICREQ) {
        // 輸入荷捌依頼プロセスの時
        table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageImportCustoms'}));
        if (!table) {
          return [];
        }
        table.groups = _.filter(table.groups, {variableName: 'importCustomsClearanceGrp'});
      }
      if (!table) {
        return [];
      }
      return [table];
    },
    // Transfer Term情報群 NOTE: Ph2改善要望でTransfer Termはタブ外に表示する
    transferTermsGroup() {
      let table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageTransportation'}));
      if (!table) return [];
      table.groups = _.filter(table.groups, { variableName: 'transferTermsGrp' });
      return [table];
    },
    documentSignerGroup() {
      let table = this.s.cloneDeep(_.find(this.schemas.tables, {variableName: 'linkageFinalDocs'}) || '');
      if (!table) return [];
      table.groups = _.filter(table.groups, { variableName: 'certificateOfOriginGrp' });
      if (_.isEmpty(table.groups)) return [];
      table.groups[0].name = 'CO Document Signer';
      table.groups[0].children = _.filter(table.groups[0].children, child => {
        return this.documentSigners.includes(child.key);
      });
      table.groups[0].children = _.sortBy(table.groups[0].children, child => {
        return this.documentSigners.findIndex(key => child.key === key);
      });

      return [table];
    },
    // Goodsタブの中身
    schemaTab1() {
      // LC保管の場合は非表示
      if (this.processType === PROCESS_TYPE.LCACCEPT) {
        return [];
      }
      // 許可書保管、IP保管の場合は非表示、契約締結、IP発行依頼でTo側の時は非表示
      if (this.processType === PROCESS_TYPE.ECPERMIT ||
          this.processType === PROCESS_TYPE.ICPERMIT ||
          this.processType === PROCESS_TYPE.MIIP ||
          (this.processType === PROCESS_TYPE.COCOS && this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.TO) ||
          (this.processType === PROCESS_TYPE.COPO && this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.TO) ||
          (this.processType === PROCESS_TYPE.MIRIP && this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.TO))
      {
        return [];
      }
      // CO発行では非表示
      // LC保管の場合は非表示
      if (this.processType === PROCESS_TYPE.FDCOI) {
        return [];
      }
      let goods = this.s.cloneDeep(_.filter(this.schemas.tables, table => {
        return table.variableName.includes('GoodsLine');
      }));
      const ORDER = [
        'linkageGoodsLinePrice',
        'linkageGoodsLine',
        'importGoodsLinePriceSeparate',
        'linkageGoodsLineType',
        'linkageGoodsLineCo',
        'linkageGoodsLinePacking',
      ];

      goods = _.orderBy(goods, [o => {return _.findIndex(ORDER, name => name === o.variableName)}], ['asc']);

      // linkageGoodsLinePriceにAmountGrpを追加
      let schemas = this.s.cloneDeep(this.schemas);
      const goodsProcessType = (this.processType !== PROCESS_TYPE.FDCOR && this.processType !== PROCESS_TYPE.FDCOI) ? processTabType.goods[this.processType] : null;
      if (goodsProcessType) {
        let table = _.find(this.schemas.tables, { variableName: goodsProcessType.tableName });
        goods[1].extend = _.filter(table.groups, group => {
          return goodsProcessType.groups.includes(group.variableName);
        });
        goods[1].extendTableName = table.variableName;
      }
      // goodsタブに他のテーブルの該当項目を追加
      _.forEach(schemas.tables, table => {
        if (table.variableName === 'importGoodsLinePriceSeparate') {
          // 輸入商品価格個別テーブルが重複して追加されないようにする（expensesGrpを含むため）
          return;
        }
        if (goodsProcessType) {
          table.groups = _.filter(table.groups, group => {
            return processTabType.goods.allGroup.includes(group.variableName) && !goodsProcessType.groups.includes(group.variableName);
          });
        } else {
          table.groups = _.filter(table.groups, group => {
            return processTabType.goods.allGroup.includes(group.variableName);
          });
        }
        if (table.groups && table.groups.length > 0) goods.push(table)
      });

      return goods;
    },
    // Players情報タブの中身
    schemaTab2() {
      return this.groupFilter('players');
    },
    // Logistics情報タブの中身
    schemaTab3() {
      return this.groupFilter('logistics');
    },
    // 共通情報タブの中身
    schemaTab4() {
      return this.groupFilter('information');
    },
    // エンティティ個別タブの中身
    schemaTab5() {
      // LC保管の場合は非表示
      if (this.processType === PROCESS_TYPE.LCACCEPT) {
        return [];
      }
      return _.filter(this.schemas.tables, table => {
        if (this.processType === PROCESS_TYPE.MIRIP) {
          // IP発行依頼プロセスの場合 processSeparateにIP発行依頼個別テーブルの情報が配列で含まれている 外出しのため除外する
          return !table.variableName.includes('processSeparate') && table.variableName.includes('Separate');
        }
        if (table.variableName === 'importGoodsLinePriceSeparate') {
          // 輸入商品価格個別テーブルはGoodsタブに表示するため除外する
          return false;
        }
        return table.variableName.includes('Separate') || table.variableName.includes('processSeparate');
      });
    },
    // IP発行依頼個別テーブルの中身
    IpRequestSchema() {
      return _.filter(this.schemas.tables, table => {
        return table.variableName.includes('processSeparate');
      });
    },
    // L/Cタブの表示
    hasLcdata() {
      const lcdata = _.get(this.localValue, 'linkageLc.lcpools[0].lcdata');
      return this.processType === PROCESS_TYPE.LCACCEPT && lcdata && Object.keys(lcdata).length > 0;
    },
    getParent() {
      return _.get(processNames[this.processId.replace(/\d/g, '')], 'parent');
    },
    // Applyボタンの表示可否を返却します
    needApplyButton() {
      return this.tradeManagement.actions.includes(PROCESS_OPERATION.F_APPROVE_REQ) || this.tradeManagement.actions.includes(PROCESS_OPERATION.T_APPROVE_REQ);
    },
    // Saveボタンの表示可否を返却します
    needSaveButton() {
      return this.tradeManagement.actions.includes(PROCESS_OPERATION.F_SAVING) || this.tradeManagement.actions.includes(PROCESS_OPERATION.T_SAVING);
    },
    // Registerボタンの表示可否を返却します
    needRegisterButton() {
      return this.tradeManagement.actions.includes(PROCESS_OPERATION.F_DONE) || this.tradeManagement.actions.includes(PROCESS_OPERATION.T_DONE);
    },
    // Request Draftボタンの表示可否を返却します
    needDraftPdfButton() {
      return this.processType === PROCESS_TYPE.FDCOR && this.tradeManagement.actions.includes(PROCESS_OPERATION.F_SAVING);
    },
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    // グラフィカルモード表示ボタンの表示可否
    needGraphical() {
      // 正式版のプロセスのみ対応表示 = 簡易版プロセスは非表示
      return ![PROCESS_TYPE.COCOS, PROCESS_TYPE.TPBL].includes(this.processType)
    },
    // 許可書修正・訂正通知フラグ
    amendmentFlg: {
      get() {
        const tableName = this.processType === PROCESS_TYPE.ECPERMIT ? 'exportCustomsSeparate' : 'importCustomsSeparate';
        return _.get(this, `localValue.${tableName}.amendmentGrp.amendmentFlg`, null);
      },
      set(value) {
        const tableName = this.processType === PROCESS_TYPE.ECPERMIT ? 'exportCustomsSeparate' : 'importCustomsSeparate';
        this.s.setValue(tableName, 'amendmentGrp', 'amendmentFlg', value);
      }
    },
    documentCheckbox() {
      return DOCUMENT_LINK_VARIABLES.filter(item => !/^Others\d+/.test(item.label));
    },
    documentOthersCheckbox() {
      return DOCUMENT_LINK_VARIABLES.filter(item => /^Others\d+/.test(item.label));
    }
  },
  watch: {
    errors: {
      handler() {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            _.times(5, i => {
              const el = document.getElementById(`tab${i}`);
              this.$set(this.tabError, i, el ? !!el.querySelector('.el-form-item.is-error:not(.hide_error)') : false);
            })
            const vesselName = document.getElementById('vessel_name');
            this.vesselNameContentHasError = !!vesselName.querySelector('.el-form-item.is-error:not(.hide_error)');
          });
        });
      },
      deep: true,
    },
    drawerShow(val) {
      if (val) {
        this.checkValue = this.initTypeOfWorks();
        this.documentsForm.attachedDocuments =  _.clone(this.value.documents);
        this.setDocuments();
        this.documentsForm.documents = [];
      }
    },
    hasLcdata(val) {
      if (!val && this.activeTab === 5) {
        // L/Cタブ選択中に紐付け解除した場合は、Infoタブを選択する
        this.activeTab = 3;
      } else if (val && this.activeTab !== 5) {
        this.activeTab = 5;
      }
    },
  },
  created() {
    this.WHITE_LIST = (this.processType === PROCESS_TYPE.FDCOR) ? _.uniq(_.map(ATTACH_FILE_EXTENSION_CO_VARIABLES, o => o.label.toLowerCase())) : _.uniq(_.map(ATTACH_FILE_EXTENSION_VARIABLES, o => o.label.toLowerCase()));
    // アクティブタブの初期表示
    for (let i = 0; i < 5; i++) {
      if (!_.isEmpty(this[`schemaTab${i + 1}`])) {
        this.activeTab = i;
        return
      }
    }
    // TODO LC項目入力可能になったら削除
    if (this.hasLcdata) {
      this.activeTab = 5;
    }
  },
  mounted() {
    this.checkValue = this.initTypeOfWorks();
    this.documentsForm.attachedDocuments =  _.clone(this.value.documents);
    this.setDocuments();
  },
  methods: {
    // From側のボールかどうかを返却します
    isFromTurn() {
      return [
        PROCESS_PROGRESS_STATUS.NOT_STARTED,
        PROCESS_PROGRESS_STATUS.F_INPUT_IN_PROGRESS,
        PROCESS_PROGRESS_STATUS.F_AWAITING_APPROVAL,
      ].includes(this.tradeManagement.processProgressStatus);
    },
    // From担当者かどうかを返却します
    isFromPic() {
      return this.isFromTurn() && this.roles.from && this.roles.from.userRoleCd === USER_ROLE_CD.PIC;
    },
    // To担当者かどうかを返却します
    isToPic() {
      return this.roles.to && this.roles.to.userRoleCd === USER_ROLE_CD.PIC;
    },
    // From承認者かどうかを返却します
    isFromApprover() {
      return this.roles.from && this.roles.from.userRoleCd === USER_ROLE_CD.APPROVER;
    },
    // To承認者かどうかを返却します
    isToApprover() {
      return this.roles.to && this.roles.to.userRoleCd === USER_ROLE_CD.APPROVER;
    },
    loadFile() {
      this.$refs.fileInput.click();
    },
    setFile(e) {
      const files = _.get(e, 'target.files[0]') ? e.target.files : e.dataTransfer.files;
      const _file = _.get(e, 'target.files[0]') || _.get(e, 'dataTransfer.files[0]');
      if (!_file) {
        return;
      }
      if (files.length + this.documentsForm.documents.length > 10) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[10] exceeded.');
        return;
      }
      if (_.some(files, file => {
        if (file.name.length > 100) {
          this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
          return true;
        }
      })) {
        return;
      }
      if (_.some(files, file => {
        if (file.size > 10485760) {
          this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
          return true;
        }
      })) {
        return;
      }
      if (_.some(files, file => {
        if (!this.WHITE_LIST.includes(this.s.getFileExtension(file.name))) {
          this.$store.dispatch('SHOW_ALERT', `Only files with the following extensions are allowed: ${this.WHITE_LIST.join(',')}`);
          return true;
        }
      })) {
        return;
      }

      if (typeof FileReader === 'function') {
        _.forEach(files, file => {
          const reader = new FileReader();
          reader.onload = event => {
            this.documentsForm.documents.push({
              updateDate: new Date(),
              documentSize: file.size,
              documentName: file.name,
              documentValue: event.target.result,
              documentEditFlg: DOCUMENT_EDIT_FLG.REGIST,
              documentComment: '',
              documentCommentText: '',
              documentLinkInv: null,
              documentLinkPl: null,
              documentLinkSi: null,
              documentLinkAn: null,
              documentLinkBl: null,
              documentLinkCo: null,
              documentLinkOthers1: null,
              documentLinkOthers2: null,
              documentLinkOthers3: null,
              documentLinkOthers4: null,
              documentLinkOthers5: null,
              documentLinkLabelOthers1: "",
              documentLinkLabelOthers2: "",
              documentLinkLabelOthers3: "",
              documentLinkLabelOthers4: "",
              documentLinkLabelOthers5: ""
            });
            e.target.value = '';
          };
          reader.readAsDataURL(file);
        });
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    // 添付ファイル削除（登録前）
    removeFile(index) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.documentsForm.documents.splice(index, 1);
        })
        .catch(() => {});
    },
    // 添付ファイル削除（登録後）
    removeAttachedFile(doc, index) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          doc.documentEditFlg = DOCUMENT_EDIT_FLG.DELETE;
          this.$set(this.documentsForm.attachedDocuments, index, doc);
        })
        .catch(() => {});
    },
    onCommentChange(doc, index) {
      doc.documentEditFlg = DOCUMENT_EDIT_FLG.UPDATE;
      this.$set(this.documentsForm.attachedDocuments, index, doc);
    },
    //チェックボックスの値変更時の処理
    updateUploadedDocumentsCategories(doc, item, index){
      const linkKey = `documentLink${this.getDocumentKey(item.code)}`;
      const labelKey = `documentLinkLabel${this.getDocumentKey(item.code)}`;

      if (!doc[linkKey]) {
        doc[labelKey] = "";
      }
      if (this.documentsForm.attachedDocuments.includes(doc)) {
        doc.documentEditFlg = this.DOCUMENT_EDIT_FLG.UPDATE;
        this.$set(this.documentsForm.attachedDocuments, index, doc);
      }
    },
    getDocumentKey(code){
      const Key = {
        1: "Inv",
        2: "Pl",
        3: "Bl",
        4: "An",
        5: "Si",
        6: "Co",
        7: "Others1",
        8: "Others2",
        9: "Others3",
        10: "Others4",
        11: "Others5"
      };
      return Key[code] || "";
    },
    // 承認依頼
    approvalRequest() {
      this.documentsValidation()
      .then(() => {
        this.quantityOverAlert().then(() => {
          const action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_APPROVE_REQ : PROCESS_OPERATION.T_APPROVE_REQ;
          this.$emit('approval-request', action, this.createPostDocuments());
        })
        .catch(() => {
          return false;
        });
      })
      .catch(() => {
        return false;
      });
    },
    // 下書き保存
    save() {
      this.documentsValidation()
      .then(() => {
        this.quantityOverAlert().then(() => {
          const action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_SAVING : PROCESS_OPERATION.T_SAVING;
          this.$emit('save',  action, this.createPostDocuments());
        })
        .catch(() => {
          return false;
        });
      })
      .catch(() => {
        return false;
      });
    },
    // COドラフトPDF発行申請
    draftPdf() {
      // isDraftPdf:trueで、ProcessFDCOR.vueのsaveを呼び出す
      const isDraftPdf = true;
      this.documentsValidation()
        .then(() => {
          this.quantityOverAlert().then(() => {
            const action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_SAVING : PROCESS_OPERATION.T_SAVING;
            this.$emit('save',  action, this.createPostDocuments(), false, isDraftPdf);
          })
          .catch(() => {
            return false;
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 登録
    register() {
      this.documentsValidation()
      .then(() => {
        this.quantityOverAlert().then(() => {
          const action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_DONE : PROCESS_OPERATION.T_DONE;
          this.$emit('register', action, this.createPostDocuments());
        })
        .catch(() => {
          return false;
        });
      })
      .catch(() => {
        return false;
      });
    },
    dragEnter() {
      this.dragin = true;
    },
    dragLeave() {
      this.dragin = false;
    },
    dropFile(e) {
      this.dragin = false;
      this.setFile(e)
    },
    download(doc) {
      const path = {
        documentManageId: doc.documentManageId,
        documentTrx: doc.documentTrx
      };

      $api
      .download(path, doc.documentName)
      .then(() => {
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    switchTab(index) {
      this.activeTab = index;
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    onChecked() {
      const typeOfWorks = _.reduce(this.checkValue, (ret, o) => {
        ret[o.value] = TYPE_OF_WORKS.ON;
        return ret;
      }, {
        workTypeDocumentation: TYPE_OF_WORKS.OFF,
        workTypeCustomsClearance: TYPE_OF_WORKS.OFF,
        workTypeLoading: TYPE_OF_WORKS.OFF,
        workTypeBooking: TYPE_OF_WORKS.OFF,
        workTypeMarineIp: TYPE_OF_WORKS.OFF,
      });
      this.localValue.processSeparate.typeOfWorksGrp = typeOfWorks;
    },
    // 依頼区分のチェックボックスを初期化します
    initTypeOfWorks() {
      const typeOfWorks = _.get(this.localValue, 'processSeparate.typeOfWorksGrp');
      if (!typeOfWorks) {
        return [];
      }
      return _.reduce(this.checkItems, (ret, o) => {
        if (typeOfWorks[o.value] === TYPE_OF_WORKS.ON) {
          ret.push(o);
        }
        return ret;
      }, []);
    },
    // グラフィカルドロワー表示
    showGraphical() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      this.isShowGraphical = !this.isShowGraphical;
    },
    setGraphical() {
      this.$store.commit('SET_GRAPHICAL', this.isShowGraphical);
    },
    // 送信する帳票オブジェクトを作成
    createPostDocuments() {
      const convertBooleanToNumber = (value) => (value ? 1 : 0);
      const docs = _.map(this.documentsForm.attachedDocuments, doc => {
        return {
          documentArchiveId:doc.documentArchiveId,
          documentEditFlg: doc.documentEditFlg || DOCUMENT_EDIT_FLG.NO_EDIT,
          documentComment: doc.documentCommentText,
          documentLinkInv: convertBooleanToNumber(doc.documentLinkInv),
          documentLinkPl: convertBooleanToNumber(doc.documentLinkPl),
          documentLinkSi: convertBooleanToNumber(doc.documentLinkSi),
          documentLinkAn: convertBooleanToNumber(doc.documentLinkAn),
          documentLinkBl: convertBooleanToNumber(doc.documentLinkBl),
          documentLinkCo: convertBooleanToNumber(doc.documentLinkCo),
          documentLinkOthers1: convertBooleanToNumber(doc.documentLinkOthers1),
          documentLinkOthers2: convertBooleanToNumber(doc.documentLinkOthers2),
          documentLinkOthers3: convertBooleanToNumber(doc.documentLinkOthers3),
          documentLinkOthers4: convertBooleanToNumber(doc.documentLinkOthers4),
          documentLinkOthers5: convertBooleanToNumber(doc.documentLinkOthers5),
          documentLinkLabelOthers1: doc.documentLinkLabelOthers1,
          documentLinkLabelOthers2: doc.documentLinkLabelOthers2,
          documentLinkLabelOthers3: doc.documentLinkLabelOthers3,
          documentLinkLabelOthers4: doc.documentLinkLabelOthers4,
          documentLinkLabelOthers5: doc.documentLinkLabelOthers5
        }
      });
      this.convertDocumentLinksForRequest(this.documentsForm.documents);
      const formDocs = _.map(this.documentsForm.documents, doc => _.omit(doc, ['documentManageId', 'documentTrx']));
      return [...docs, ...formDocs];
    },
    //チェックボックスの真偽値から添付ファイル区分の値に変換する処理
    convertDocumentLinksForRequest(document) {
      document.forEach(doc => {
        DOCUMENT_LINK_VARIABLES.forEach(item => {
          const key = `documentLink${this.getDocumentKey(item.code)}`;

          if(doc[key] === true) {
            doc[key] = 1;
          } else if (doc[key] === false) {
            doc[key] = 0;
          }
        });
      });
    },
    documentsValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.documentsForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
            reject();
          }
        });
      });
    },
    onValidate() {
      requestAnimationFrame(() => {
        const files = this.$refs.documentsForm.$el.querySelectorAll('.file');
        files.forEach(el => {
          if (el.querySelector('.is-error')) {
            el.classList.add('has_error');
          } else {
            el.classList.remove('has_error');
          }
        });
      });
    },
    // 同一取引内の帳票選択ダイアログを開く
    showDocumentsDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      this.dialogAddDocumentsVisible = true;
    },
    // 帳票選択ダイアログを閉じる前の確認
    documentsDialogBeforeClose(done) {
      if (this.$refs.addDocumentsDialog.selectFiles.length) {
        this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
        .then(() => {
          done();
        })
        .catch(() => {});
      } else {
        done();
      }
    },
    // 同一取引内の帳票を添付する
    addDocuments(files) {
      this.dialogAddDocumentsVisible = false;
      _.forEach(files, file => {
        const documentId = (file.processId.startsWith('FDCOR')) ? _.find(CO_DOCUMENT_ID_VARIABLES, {code: file.documentCommentText}) : null;
        this.documentsForm.documents.push({
          updateDate: new Date(),
          documentSize: file.documentSize,
          documentName: file.documentName,
          documentEditFlg: DOCUMENT_EDIT_FLG.RE_REGIST,
          // 同一フロー内の添付資料を引用する時、コメントは引用せず、新規に入力するため documentCommentはカラにする
          documentComment: documentId ? documentId.label : '',
          documentPath: file.documentPath,
          documentManageId: file.documentManageId,
          documentTrx: file.documentTrx,
        });
      });
    },
    // Player, Logistics, Informationタブの項目フィルタリング処理
    groupFilter(type) {
      // 許可書保管の場合は非表示
      // if (this.processType === PROCESS_TYPE.ECPERMIT || this.processType === PROCESS_TYPE.ICPERMIT) {
      //   return [];
      // }
      let schemas = this.s.cloneDeep(this.schemas);
      let tables = _.forEach(schemas.tables, table => {
        table.groups = _.filter(table.groups, group => {
          // Vessel NameをLogisticsタブのいちばん上に移動
          if (type === 'logistics' && group.variableName === 'logisticsLoadingGrp') {
            this.vesselName = {
              item: this.s.cloneDeep(_.find(group.children, {key: 'nameOfVessel'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            this.voyageNo = {
              item: this.s.cloneDeep(_.find(group.children, {key: 'voyageNo'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            group.children = _.reject(group.children, item => {
              return item.key === 'nameOfVessel' || item.key === 'voyageNo';
            });
          }
          if (type === 'logistics' && group.variableName === 'containerGrp') {
            this.vesselBookingNo = {
              item: this.s.cloneDeep(_.find(group.children, {key: 'vesselBookingNo'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            group.children = _.reject(group.children, item => {
              return item.key === 'vesselBookingNo';
            });
          }

          if (type === 'information' && group.variableName === 'certificateOfOriginGrp') {
            group.children = _.reject(group.children, item => {
              return this.documentSigners.includes(item.key);
            });
          }

          // 項目がすべて非表示なら除外する
          if (_.every(group.children, item => item.hide)) {
            return false;
          }
          if (type === 'information') {
            // Informationタブは、players, logisticsタブにないものを表示する
            return !(processTabType.players.includes(group.variableName) || processTabType.logistics.includes(group.variableName));
          } else {
            return processTabType[type].includes(group.variableName);
          }
        });
      });
      return this.groupReject(tables);
    },
    // Player, Logistics, Informationタブの項目リジェクト処理
    groupReject(tables) {
      return _.reject(tables, table => {
        // NOTE: プロセスによって変わる必須項目のグループを除外
        if (this.processType === PROCESS_TYPE.COPO &&  table.variableName.includes('linkageContract')) {
          // PO送付プロセスの時
          table.groups = _.reject(table.groups, group => {
            return group.variableName === 'poNoDateGrp';
          });
        }  else if (this.processType === PROCESS_TYPE.COCOS &&  table.variableName.includes('linkageContract')) {
          // 契約締結プロセスの時
          table.groups = _.reject(table.groups, group => {
            return group.variableName === 'contractNoDateGrp';
          });
        } else if (this.processType === PROCESS_TYPE.ECREQ && table.variableName.includes('linkageExportCustoms')) {
          // 輸出船積依頼プロセスの時
          table.groups = _.reject(table.groups, group => {
            return group.variableName === 'exCustomClearanceGrp';
          });
        } else if (this.processType === PROCESS_TYPE.MIIP && table.variableName.includes('linkageMarineInsurance')) {
          // I/P保管プロセスの時
          table.groups = _.reject(table.groups, group => {
            return group.variableName === 'insurancePolicyGrp';
          });
        } else if ((this.processType === PROCESS_TYPE.FDDOC || this.processType === PROCESS_TYPE.FDCRTDOC) && table.variableName.includes('linkageFinalDocs')) {
          // 船積書類送付プロセスの時
          table.groups = _.reject(table.groups, group => {
            return group.variableName === 'commercialInvoiceGrp';
          });
        } else if (this.processType === PROCESS_TYPE.ICREQ && table.variableName.includes('linkageImportCustoms')) {
          // 輸入荷捌依頼プロセスの時
          table.groups = _.reject(table.groups, group => {
            return group.variableName === 'importCustomsClearanceGrp';
          });
        }
        // goodsタブに表示するグループを除外
        // タブ外のtransferTermsGrpを除外
        table.groups = _.reject(table.groups, group => {
          return processTabType.goods.allGroup.includes(group.variableName) || group.variableName === 'transferTermsGrp';
        });

        // from、toに〇がついてない項目を除外
        table.groups = _.reject(table.groups, group => {
          return !_.some(group.children, item => {
            return item[this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'] && item.controlType !== false;
          });
        });

        // エンティティ個別タブに表示するテーブルと、groupsが空のテーブルを除外
        return table.variableName.includes('Separate') || table.variableName.includes('GoodsLine') || table.variableName.includes('processSeparate') || table.groups.length === 0;
      });
    },
    // 許可書紐づけダイアログを表示します
    showPermitDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      this.dialogAddPermitVisible = true;
    },
    // 許可書を紐づけます
    addPermit(row) {
      this.dialogAddPermitVisible = false;
      this.$emit('add-permit', row);
    },
    // 許可書の紐づけを解除します
    removePermit() {
      this.$emit('remove-permit');
    },
     // I/P,D/Nダイアログを表示します
    showIpDnDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      this.dialogAddIpDnVisible = true;
    },
    // I/P,D/Nを紐づけます
    addIpDn(selection) {
      this.dialogAddIpDnVisible = false;
      this.$emit('add-ip-dn', selection);
    },
    // I/P,D/Nの紐づけを解除します
    removeIpDn(row) {
      this.$emit('remove-ip-dn', row);
    },
    // L/Cダイアログを表示します
    showLcDialog() {
      getSelection().removeAllRanges(); // テキストの選択をクリアします
      this.dialogAddLcVisible = true;
    },
    // L/Cを紐づけます
    addLc(selection) {
      this.dialogAddLcVisible = false;
      this.$emit('add-lc', selection);
    },
    // L/Cの紐づけを解除します
    removeLc(row) {
      this.$emit('remove-lc', row);
    },
    // Booking依頼キャンセル
    onCheckedBookingCancelFlg(val) {
      this.localValue.bookingCancelFlg = val;
    },
    // 連番項目init処理
    initSerial(edit) {
      _.forEach(this.$refs.tab2, list => {
        list.initSerial(edit);
      })
    },
    // サブフローで親フローの商品の数量を超えていた場合のアラート
    async quantityOverAlert() {
      // console.log(this.isSubFlow, this.localValue.linkageGoodsLine, this.parentGoodsLine.length)
      if (!this.isSubFlow) return Promise.resolve()
      let result = true
      if (this.localValue.linkageGoodsLine && this.parentGoodsLine.length) {
        _.forEach(this.parentGoodsLine, item => {
          // 親の合計数量
          const parentQuantity = item.quantityGrp.quantity1 || 0
          // 親と同じprevGoodsLineId、prevGoodsLineSeqを持つ子のグッズ
          const childGoods = _.filter(this.localValue.linkageGoodsLine, goods => {
            return goods.goodsGrp.prevGoodsLineId === item.goodsGrp.prevGoodsLineId && goods.goodsGrp.prevGoodsLineSeq === item.goodsGrp.prevGoodsLineSeq;
          })
          if (!childGoods || !childGoods.length) return
          // 子の数量合計
          const childQuantity = _.reduce(childGoods, (total, child) => {
            total = (child.quantityGrp.quantity1 || 0) + total
            return total;
          }, 0);
          // console.log(parentQuantity, childQuantity)
          if (parentQuantity < childQuantity) {
            result = false
            return false
          }
        });
      }
      if (result) return Promise.resolve()
      else {
        await this.$store.dispatch('SHOW_CONFIRM', 'Exceeded maximum number of quantity.')
          .then(() => { return Promise.resolve() })
          .catch(() => { return Promise.reject() })
      }
    },
    // 添付ファイルの削除可否チェック
    // IP保管プロセスにおいてはプールから紐づけた添付ファイルは削除不可とする
    checkAttachmentDeletable(documentManageId) {
      if (this.processType === PROCESS_TYPE.MIIP) {
        // IP保管プロセス
        const latest = _.get(this, 'localValue.ipDnLatest', []);
        return !_.some(latest, (item) => {
          return _.some(item.attachmentFiles, (file) => {
            return file.documentManageId === documentManageId;
          });
        });
      // NOTE: Ph6-2ではIP保管プロセスのみ対応
      // } else if (this.processType === PROCESS_TYPE.ECPERMIT || this.processType === PROCESS_TYPE.ICPERMIT) {
      //   // 輸出入許可証保管プロセス
      //   const latestDocumentManageId = _.get(this, 'localValue.permitLatest.attachmentFile.documentManageId', null);
      //   return !(latestDocumentManageId === documentManageId);
      } else {
        return true;
      }
    },
    setDocuments() {
      if (this.value.documents) {
        //チェックボックスの更新処理
        this.documentsForm.attachedDocuments.forEach((doc) => {
          DOCUMENT_LINK_VARIABLES.forEach(item => {
            const key = 'documentLink' + this.getDocumentKey(item.code)
            this.$set(doc, key, Boolean(doc[key]));
          });
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .process_detail_inner {
    width: 990px;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;

      .tw_entity_icon {
        margin-right: 8px;
      }

      .title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title_inner {
          display: flex;
          align-items: center;
        }

        .title {
          font-weight: bold;
          font-size: 20px;
          line-height: 35px;
          margin-right: 8px;
        }
      }

      .tw_status_icon {
        margin-right: 24px;
      }

      .tw_button {
        margin-right: 24px;
      }
    }

    .process_memo {
      width: 100%;
      max-width: max-content;
      font-size: 14px;
      font-weight: bold;
      padding: 4px 8px;
      border-radius: 4px;
      background: $color_gray_300;
    }

    .sub_buttons {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 8px;

      .column {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .tw_button {
          margin-left: 24px;
        }

        .toggle_button {
          margin-bottom: 0;
          margin-left: 0;
        }
      }
    }

    table.card {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      box-shadow: 0;
      overflow: hidden;
      margin-bottom: 20px;
      // box-shadow: 0px 4.11278px 8.22556px rgba(170, 170, 204, 0.5);

      &.separate{
        margin-bottom: 16px;
        ::v-deep .tw_process_list > .inner {
          display: none;
        }

        &.last {
          margin-bottom: 32px;
        }

        // &.document_signer {
        //   ::v-deep .co_signer_title {
        //     width: 100%;
        //   }
        // }
      }

      // &#tab0 {
      //   ::v-deep .tw_process_list:nth-child(2) > .inner {
      //     display: none;
      //   }
      // }
    }

    .attach {
      display: flex;
      justify-content: space-between;
      .head {
        display: flex;
        align-items: center;
      }
      .amendment_flg {
        margin: 0 auto 0 32px;
      }
    }

    .attachment {
      position: relative;
      font-size: 14px;
      line-height: 20px;

      .file {
        position: relative;
        margin-top: 8px;
        padding-left: 16px;
        padding-right: 48px;
        border-radius: 6px;
        padding-top: 12px;
        padding-bottom: 12px;
        // padding-bottom: 24px;
        background: $color_gray_100;

        &:first-child {
          margin-top: 12px;
        }

        &.has_error {
          background: rgba(224, 0, 1, 0.03);
        }

        .inner {
          display: block;
          align-items: center;
          width: 100%;

          > .el-tooltip {
            margin-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $color_dark_blue;
          }
        }

        .user {
          max-width: 200px;
          margin-right: 12px;
          display: flex;
          align-items: center;

          font-size: 12px;
          line-height: 20px;
          color: $color_black;

          .from_name {
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .close_times {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 50%;
          right: 16px;
          margin-top: -12px;
          cursor: pointer;
        }
      }

      .meta {
        flex-shrink: 0;
      }

      a.file_name {
        // max-width: 710px;
        color: $color_dark_blue;
        cursor: pointer;
        text-decoration: none;
      }

      .el-form-item {
        margin: 0;
      }

      .el-textarea, .el-select.document_id {
        display: block;
        width: 100%;
        margin-top: 8px;
        padding-right: 17px;
      }

      ::v-deep .el-input__inner {
        background: white;
        padding-right: 50px;
      }

      ::v-deep .el-textarea__inner {
        background: white;
        resize: none;
      }

      ::v-deep .el-form-item__error {
        position: relative;
      }

      ::v-deep .el-textarea.is-disabled .el-textarea__inner {
        background: none;
        color: $color_black;
      }

      .choose_file {
        position: relative;
        display: inline-block;
        // color: $color_dark_blue;
        // margin-top: 8px;
        // font-size: 12px;
        // line-height: 18px;
        // cursor: pointer;

        .file_input {
          position: absolute;
          width: 1px;
          height: 1px;
          z-index: -1;
          pointer-events: none;
        }
      }

      .droparea {
        display: flex;
        // position: absolute;
        // display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 138px;
        margin-top: 24px;
        // left: 0;
        // right: 0;
        // top: 28px;
        // height: calc(100% - 28px);
        // min-height: 138px;
        background: $color_gray_100;
        border-radius: 6px;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        color: $color_dark_blue;
        pointer-events: none;
        z-index: 2;

        img {
          display: block;
          width: 84px;
          height: auto;
          margin-bottom: 9px;
        }
      }

      &.enter > * {
        pointer-events: none;
      }

      &.enter .droparea {
        border: 1px dashed $color_dark_blue;
      }
    }

    .buttons {
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      padding: 40px 0;
      background: $color_white;
      margin-left: -20px;
      padding-right: 20px;
      width: calc(100% + 40px);
      z-index: 3;

      .tw_button {
        margin-left: 16px;
        margin-right: 0;
      }
    }

  }

  .vessel_name {
    td {
      padding: 32px 22px 0 39px;
    }
    &.has_error {
      background: rgba(224, 0, 1, 0.03);
    }
  }

  .checkbox-group {
    .el-checkbox {
      margin-right: 11px;
    }
  }
  .others_checkbox {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
  }

</style>
<style lang="scss">
.checkbox-group{
  .el-checkbox__label {
    font-size: 14px;
  }
}
.document_input {
  input[readonly] {
    cursor: not-allowed;
  }
  span{
    pointer-events: none;
  }
  .el-input__count-inner {
    background-color:white !important;
  }
} 
</style>
