import _ from 'lodash';
import { $api, mixinCancelRequests } from '@/store/ApiClient';
import { ABLE_FLG, TYPE_OF_WORKS, PROCESS_TYPE, UNIT_ROUND_TYPE_VARIABLES, FREE_CHARGE_FLG_VARIABLES, PERMIT_LINK_EDIT_FLG, IP_DN_LINK_EDIT_FLG, LC_LINK_EDIT_FLG, MAIN_FLOW_FLG, GOODS_ATTR_ID, PAYMENT_TERMS, RESPONSIBLE_FLG, IS_LATEST_PROCESS_IN_FLOW, REQUIRES_LATEST_PROCESS_IN_FLOW, IP_REGIST_TYPE } from 'lib-tw-common';
import { processNames } from '@/dictionaries/map.js';
import { createGoodsLineSeparate } from '@/utils/createGoodsLine';
import { createIpDnData, excludeDuplicatesIpData, copyIpDataFromIppools } from '@/utils/createIpDnData';
import mixinValidation from '@/utils/mixinValidation.js';
import mixinTsValidation from '@/utils/mixinTsValidation';
import mixinCalcUtils from '@/utils/mixinCalcUtils.js';
import mixinSetCo from '@/utils/mixinSetCo.js';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwCustomer from '@/components/molecules/TwCustomer';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import TwTradingInformation from '@/components/molecules/TwTradingInformation';
import TwHeader from '@/components/organisms/TwHeader';
import TwDrawer from '@/components/organisms/TwDrawer';
import TwProcessDetail from '@/components/templates/TwProcessDetail';
import TwEditProcessInner from '@/components/templates/TwEditProcessInner';
import TwMailDetailInner from '@/components/templates/TwMailDetailInner';
import TwNewMail from '@/components/templates/TwNewMail';
import TwPdfViewer from '@/components/templates/TwPdfViewer';
import TwTradingDetailInner from '@/components/templates/TwTradingDetailInner';
import { formatQuantity, formatCurrency, formatNumberString } from '@/utils/searchUtil.js';
// import TwToTop from '@/components/atoms/TwToTop';
import transportationControl from '@/dictionaries/transportationControl.json';
import lcTypes from '@/dictionaries/lcPool/lcTypes.js';
import { getProcessNumber } from './entity';

const conflictFlags = [
  'updateCommonItemsExclusiveFlag1',
  'updateCommonItemsEntitySpecificItemsExclusiveFlag2',
  'overwritePredecessorEntityExclusiveFlag3',
  'overwritingCommonItemsEntitySpecificItemsExclusiveFlag4',
];

const commentUpdateInterval = 60 * 1000 // in milli second

export default {
  inject: ['reload'],
  mixins: [mixinValidation, mixinCalcUtils, mixinCancelRequests, mixinTsValidation, mixinSetCo],
  props: {
    entityId: [String, Number],
    processSeq: [String, Number],
    processTrx: [String, Number],
    _processId: String,
  },
  components: {
    TwEntityIcon,
    TwCustomer,
    TwBreadcrumbs,
    TwTradingInformation,
    TwHeader,
    TwDrawer,
    TwProcessDetail,
    TwEditProcessInner,
    TwMailDetailInner,
    TwNewMail,
    // TwToTop,
    TwPdfViewer,
    TwTradingDetailInner,
  },
  provide() {
    return {
      s: this,
      tab: null,
    }
  },
  data() {
    return {
      res: {},
      cloneItems: {},
      transformInformation: {},
      communicationDestinations: {users: []},
      hasBottom: true,
      mode: null,
      noticeHistoryId: null,
      approvalProcess: null,
      roles: {},
      versionNo: this.processTrx,
      currentMailItems: [],
      conflictFlags: conflictFlags,
      draftValid: false,
      amountOverflow: [],
      measurementOverflow: [],
      pdfSrc: null,
      pdfRequestTag: null,
      parentTradingId: null,
      parentFlowId: null,
      commentAutoUpdateTimer: null
    };
  },
  computed: {
    companyId() {
      return this.$store.state.companyId;
    },
    title() {
      return `${this.tradeManagement.tradingManageName}<small>（${this.tradeManagement.tradingFlowName}）</small>`;
    },
    processName() {
      const processNumber = getProcessNumber(this.processId);
      if (processNumber) {
        return this.$t(`Process.${this.processId.replace(/\d/g, '')}`) + processNumber;
      }
      return this.$t(`Process.${this.processType.replace('PT_', '').toUpperCase()}`);
    },
    isShipper() {
      return this.$store.getters.isShipper;
    },
    isFwd() {
      return this.$store.getters.isFwd;
    },
    breadcrumbs() {
      if (this.isArchive) {
        return [
          {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
          {label: this.$t('BreadCrumbs.Archive List'), name: 'Archive'},
          {label: this.processName, to: ''},
        ];
      } else if (this.isShipper || this.isFwd) {
        return [
          {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
          {label: this.$t('BreadCrumbs.Trading List'), name: this.$store.state.tradingListMode},
          {label: this.tradeManagement.tradingManageName, to: `/trading/${this.tradeManagement.tradingId}?expand=${this.tradeManagement.tradingFlowId}`},
          {label: this.processName, to: ''},
        ];
      } else {
        return [
          {label: this.$t('BreadCrumbs.Dashboard'), to: '/'},
          {label: this.processName, to: ''},
        ];
      }
    },
    getParent() {
      return _.get(processNames[this.processId.replace(/\d/g, '')], 'parent');
    },
    from() {
      return this.tradeManagement.from;
    },
    to() {
      return this.tradeManagement.to;
    },
    owner() {
      return this.tradeManagement.owner;
    },
    fromTo() {
      return this.tradeManagement.responsibleFlg === 1 ? 'from' : 'to';
    },
    versions() {
      return this.res.versionList || [];
    },
    bcStatus() {
      // BC_CREATE_STATUS	BC登録ステータス
      // UNREGISTERED	0		未登録
      // REGISTERING	1 依頼中
      // REGISTRATION_END 2 登録完了
      // REGISTRATION_ERROR 3 登録エラー
      return _.get(this, 'res.bcTradingArchive.bcSubmitStatus');
    },
    isArchive() {
      return this.$route.name.includes('Archive');
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    // 商品表示用配列を生成します
    goodsLineData() {
      if (!this.res.linkageGoodsLine) {
        return [];
      }
      return _.map(this.res.linkageGoodsLine, goods => {
        const price = _.find(this.res.linkageGoodsLinePrice, p => {
          return p.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
        });
        return {
          goodsId: _.get(goods, 'goodsGrp.goodsId') || null,
          goodsShortName: _.get(goods, 'goodsGrp.goodsShortName') || null,
          quantity1: formatQuantity(goods.quantityGrp.quantity1, goods.quantityGrp.unit1),
          unitRoundType1: _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: _.get(goods, 'quantityGrp.unitRoundType1')}), 'label') || null,
          price: price ? `${formatCurrency(price.priceGrp.price, price.priceGrp.currency, this.getCurrencyMinorUnit(price.priceGrp.currencyId))}` : null,
          amount: price ? formatCurrency(price.amountGrp.amount, price.priceGrp.currency, this.getCurrencyMinorUnit(price.priceGrp.currencyId)) : null,
          quantity2: formatQuantity(goods.quantityGrp.quantity2, goods.quantityGrp.unit2),
          unitRoundType2: _.get(_.find(UNIT_ROUND_TYPE_VARIABLES, {code: _.get(goods, 'quantityGrp.unitRoundType2')}), 'label') || null,
          goodsRefNo1: _.get(goods, 'goodsGrp.goodsRefNo1') || null,
          goodsRefNo2: _.get(goods, 'goodsGrp.goodsRefNo2') || null,
          countryOfOriginName: _.get(goods, 'goodsGrp.countryOfOriginName') || null,
          noCommercialFlag:  price ? (_.get(_.find(FREE_CHARGE_FLG_VARIABLES, {code: _.get(price, 'commercialGrp.noCommercialFlag')}), 'label') || null) : null,
          goodsLineId: goods.keyGrp.goodsLineId,
          goodsLineSeq: goods.keyGrp.goodsLineSeq,
        }
      })
    },
    // 梱包のテーブル表示用のデータを生成します
    goodsLinePackingItems() {
      if (!this.res.linkageGoodsLinePacking) {
        return [];
      }
      const ret = _.map(this.res.linkageGoodsLinePacking, packing => {
        const goods = _.find(this.res.linkageGoodsLine, g => {
          return g.keyGrp.goodsLineSeq === packing.keyGrp.goodsLineSeq;
        });
        return {
          goodsId: _.get(goods, 'goodsGrp.goodsId') || null,
          goodsShortName: _.get(goods, 'goodsGrp.goodsShortName') || null,
          netTotal: formatQuantity(packing.netGrossMeasurementGrp.netTotal),
          grossTotal: formatQuantity(packing.netGrossMeasurementGrp.grossTotal),
          weightUnit: packing.netGrossMeasurementGrp.weightUnit,
          numberOfContents: formatQuantity(packing.netGrossMeasurementGrp.numberOfContents),
          numberOfPackages: formatQuantity(packing.netGrossMeasurementGrp.numberOfPackages),
          packing: (_.get(packing, 'netGrossMeasurementGrp.packing') || '').split(',')[0], // カンマの前を表示
          netLength: formatQuantity(packing.netGrossMeasurementGrp.netLength),
          netWidth: formatQuantity(packing.netGrossMeasurementGrp.netWidth),
          netHeight: formatQuantity(packing.netGrossMeasurementGrp.netHeight),
          netMeasurementTotal: formatQuantity(packing.netGrossMeasurementGrp.netMeasurementTotal, '', 6),
          grossLength: formatQuantity(packing.netGrossMeasurementGrp.grossLength),
          grossWidth: formatQuantity(packing.netGrossMeasurementGrp.grossWidth),
          grossHeight: formatQuantity(packing.netGrossMeasurementGrp.grossHeight),
          grossMeasurementTotal: formatQuantity(packing.netGrossMeasurementGrp.grossMeasurementTotal, '', 6),
          lwhUnit: packing.netGrossMeasurementGrp.lwhUnit,
          measurementUnit: packing.netGrossMeasurementGrp.measurementUnit,
          goodsLineId: packing.keyGrp.goodsLineId,
          goodsLineSeq: packing.keyGrp.goodsLineSeq,
          packingLineSeq: packing.keyGrp.packingLineSeq,
          packingSublineSeq: packing.keyGrp.packingSublineSeq,
        }
      });

      // 商品梱包をgoodsLine順に並べ替えます
      const goodsLineIndexes = _.map(this.res.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq');
      });
      return _.sortBy(ret, (packing) => {
        const index = _.indexOf(goodsLineIndexes, packing.goodsLineSeq);
        return index === -1 ? undefined : index;
      });
    },
    // 商品個別表示用のデータを生成します（ドロワー）
    goodsLineSeparateItems() {
      if (!_.get(this.cloneItems, 'goodsLineSeparate.goods')) {
        return [];
      }
      const ret = _.map(this.cloneItems.goodsLineSeparate.goods, goods => {
        return {
          hsCode: this.processType === PROCESS_TYPE.ECPERMIT ? goods.exportGoodsDetailsGrp.hsCode : goods.importGoodsDetailsGrp.importHsCode,
          goodsName: goods.commonGoodsDetailsGrp.goodsName,
          quantity1: formatNumberString(goods.commonGoodsDetailsGrp.quantity1),
          quantity1UnitId: goods.commonGoodsDetailsGrp.quantity1UnitId,
          quantity2: formatNumberString(goods.commonGoodsDetailsGrp.quantity2),
          quantity2UnitId: goods.commonGoodsDetailsGrp.quantity2UnitId,
          amount: formatNumberString(this.processType === PROCESS_TYPE.ECPERMIT ? goods.exportGoodsDetailsGrp.declarationAmount : goods.importGoodsDetailsGrp.customsAmount),
          goodsCode: _.get(goods, 'importGoodsDetailsGrp.goodsCode'),
          originCountry: _.get(goods, 'importGoodsDetailsGrp.originCountry'),
          tariffRate: _.get(goods, 'importGoodsDetailsGrp.tariffRate'),
          tariff: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.tariff')),
          tariffExemptionValue: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.tariffExemptionValue')),
          consumptionTaxRate1: _.get(goods, 'importGoodsDetailsGrp.consumptionTaxRate1'),
          consumptionTaxValue1: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.consumptionTaxValue1')),
          consumptionTaxExemptionValue1: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.consumptionTaxExemptionValue1')),
          currency: 'JPY',
          goodsLineSeparateSeq: goods.keyGrp.goodsLineSeparateSeq,
        }
      });

      return ret;
    },
    // 商品個別表示用のデータを生成します（プロセス詳細）
    goodsLineSeparatePreviewItems() {
      if (!_.get(this.res, 'goodsLineSeparate.goods')) {
        return [];
      }
      const ret = _.map(this.res.goodsLineSeparate.goods, goods => {
        return {
          hsCode: this.processType === PROCESS_TYPE.ECPERMIT ? goods.exportGoodsDetailsGrp.hsCode : goods.importGoodsDetailsGrp.importHsCode,
          goodsName: goods.commonGoodsDetailsGrp.goodsName,
          quantity1: formatNumberString(goods.commonGoodsDetailsGrp.quantity1),
          quantity1UnitId: goods.commonGoodsDetailsGrp.quantity1UnitId,
          quantity2: formatNumberString(goods.commonGoodsDetailsGrp.quantity2),
          quantity2UnitId: goods.commonGoodsDetailsGrp.quantity2UnitId,
          amount: formatNumberString(this.processType === PROCESS_TYPE.ECPERMIT ? goods.exportGoodsDetailsGrp.declarationAmount : goods.importGoodsDetailsGrp.customsAmount),
          goodsCode: _.get(goods, 'importGoodsDetailsGrp.goodsCode'),
          originCountry: _.get(goods, 'importGoodsDetailsGrp.originCountry'),
          tariffRate: _.get(goods, 'importGoodsDetailsGrp.tariffRate'),
          tariff: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.tariff')),
          tariffExemptionValue: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.tariffExemptionValue')),
          consumptionTaxRate1: _.get(goods, 'importGoodsDetailsGrp.consumptionTaxRate1'),
          consumptionTaxValue1: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.consumptionTaxValue1')),
          consumptionTaxExemptionValue1: formatNumberString(_.get(goods, 'importGoodsDetailsGrp.consumptionTaxExemptionValue1')),
          currency: 'JPY',
          goodsLineSeparateSeq: goods.keyGrp.goodsLineSeparateSeq,
        }
      });

      return ret;
    },
    // I/P情報表示用のデータを生成します（ドロワー）
    ipItems() {
      if (!_.get(this.cloneItems, 'linkageMarineInsurance.ippools')) {
        return [];
      }
      // キー項目が重複するI/Pを除外
      return excludeDuplicatesIpData(this.createIpItems(this.cloneItems));
    },
    // I/P情報表示用のデータを生成します（プロセス詳細）
    ipPreviewItems() {
      if (!_.get(this.res, 'linkageMarineInsurance.ippools')) {
        return [];
      }
      // キー項目が重複するI/Pを除外
      return excludeDuplicatesIpData(this.createIpItems(this.res));
    },
    // D/N情報表示用のデータを生成します（ドロワー）
    dnItems() {
      if (!_.get(this.cloneItems, 'marineInsuranceSeparate.dnpools')) {
        return [];
      }
      return this.createDnItems(this.cloneItems);
    },
    // D/N情報表示用のデータを生成します（プロセス詳細）
    dnPreviewItems() {
      if (!_.get(this.res, 'marineInsuranceSeparate.dnpools')) {
        return [];
      }
      return this.createDnItems(this.res);
    },
    goodsLineCoPreviewItems() {
      if (!this.res.linkageGoodsLineCo) {
        return [];
      }
      const ret = _.map(this.res.linkageGoodsLineCo, goods => {
        const goodsLine = _.find(this.res.linkageGoodsLine, g => {
          return g.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
        });
        return {
          goodsLineSeq: goods.keyGrp.goodsLineSeq,
          goodsId: goods.goodsGrp.goodsId,
          goodsName: goodsLine.goodsGrp.goodsShortName,
          countryOfOriginName: goods.goodsGrp.countryOfOriginName,
          quantity1: formatQuantity(goods.quantityGrp.quantity1, goods.quantityGrp.unit1),
          coGoodsName: goods.goodsGrp.goodsShortName,
          coApplicationNo: goods.keyGrp.coApplicationNo || '',
          remarks1: goods.remarksGrp.remarks1,
        }
      });

      return ret;
    },
    // サブフローかどうか
    isSubFlow() {
      return _.get(this.res, 'tradeManagement.mainFlowFlg') === MAIN_FLOW_FLG.SUB;
    },
    // 親フローの分納元商品明細
    parentGoodsLine() {
      return _.get(this.res, 'parentGoodsLine') || [];
    },
    containerTop() {
      return `top: ${this.$store.state.headerHeight}px`
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.path.includes('edit')) {
        vm.$store.commit('SET_DRAWER', true);
        vm.mode = 'edit';
      } else {
        vm.$store.commit('SET_DRAWER', false);
        vm.mode = null;
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path.includes('edit')) {
      this.$store.commit('SET_DRAWER', true);
      this.mode = 'edit';
    } else {
      this.$store.commit('SET_DRAWER', false);
      this.mode = null;
    }
    if (from.path.replace('/edit') !== to.path) {
      next();
      this.reload();
    } else {
      next();
    }
  },
  created() {
    this.approvalProcess = _.get(_.find(processNames, {processType: this.processType}), 'approval');
      // 1回目の BFF API コールでは初期表示に必要な全処理を行い、 2回目以降はコメント更新のみを対象とする
    this.fetch();
    this.commentAutoUpdateTimer = setInterval(() => {
      this.fetch(true, undefined);
    }, commentUpdateInterval)
  },
  beforeDestroy() {
    clearInterval(this.commentAutoUpdateTimer)
    this.cloneItems = {};
    this.tradeManagement = null;
    this.res = null;
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    onValidate(propName, valid) {
      if (valid) {
        this.errors = _.reject(this.errors, e => {
          return e === propName;
        });
      } else {
        this.errors = _.uniq(this.errors.concat(propName));
      }
    },
    // 編集した値を反映
    setValue(tableName, groupName, propertyName, value) {
      this.$set(this.cloneItems[tableName][groupName], propertyName, value);
    },
    // 編集した値を配列に反映
    setArrayValue(tableName, groupName, arrayName, index, propertyName, value) {
      this.$set(this.cloneItems[tableName][groupName][arrayName][index], propertyName, value);
    },
    // 商品の編集した値を反映
    setGoodsValue(tableName, index, groupName, propertyName, value) {
      if (index < 0) {
        return;
      }
      // 商品属性の場合は一階層深くなる
      this.$set(this.cloneItems[tableName][index][groupName], propertyName, value);
    },
    // 編集権限をチェックします
    checkEditPermission(res) {
      if (this.$route.name.includes('EditProcess') && res.tradeManagement.editAbleFlg === ABLE_FLG.FALSE) {
        // 403扱いする
        this.$store.dispatch('SHOW_ALERT', 'Either you do not have permission to view the data or the data cannot be found.');
        return false;
      }
      return true;
    },
    // 許可書データを反映
    setPermit(row) {
      // 許可書プール詳細照会BFFを叩く
      // const res = this.processType === PROCESS_TYPE.ECPERMIT ? ecPermitStub : icPermitStub;
      let res = {};

      // bff_ncp_3 輸出許可書プール詳細照会BFF
      // bff_ncp_7 輸入許可書プール詳細照会BFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'get_/v1/nc-pool-datas/export/detail' : 'get_/v1/nc-pool-datas/import/detail';
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: apiCode,
          query: {
            declarationNo: row.declarationNo,
            declarationDate: row.declarationDate,
            trx: row.trx,
          }
        }
      };
      $api.request(params)
      .then(result => {
        res = result;
        // PDF情報
        const attachmentFiles = res.pdfFilePath ? {
          attachDocumentName: res.pdfFileName,
          attachDocumentPath: res.pdfFilePath,
        } : null;
        // POST用permitオブジェクト
        this.$set(this.cloneItems, 'permit', {
          declarationNo: res.declarationNo,
          declarationDate: res.declarationDate,
          declarationTrx: res.trx,
          permitDocumentName: this.$options.filters.fileName(res.permitFilePath) || null,
          permitDocumentPath: res.permitFilePath || null,
          permitDocumentPdfName: res.pdfFileName ? res.pdfFileName : null,
          permitDocumentPdfPath: res.pdfFilePath ? res.pdfFilePath : null,
          attachmentFiles: attachmentFiles,
          // 許可書紐づけ解除と許可書紐づけを一度のBFFコールで実行するときはREPLACEを指定
          permitLinkEditFlg: _.get(this.res, 'permitLatest.declarationNo') ? PERMIT_LINK_EDIT_FLG.REPLACE : PERMIT_LINK_EDIT_FLG.CREATE,
        });

        const user = this.$store.state.userProfile;
        // PDF情報
        const attachmentFile = res.pdfFilePath ? {
          pdfFilePath: res.pdfFilePath,
          pdfFileName: res.pdfFileName,
        } : null;
        // 画面表示用permitLatestオブジェクト
        this.$set(this.cloneItems, 'permitLatest', {
          updateUserName: user.userName,
          updateUserIcon: user.userIcon,
          declarationNo: res.declarationNo,
          declarationDate: res.declarationDate,
          declarationTrx: res.trx,
          attachmentFile: attachmentFile,
          isAddPermit: true, // ダイアログから選択した許可書かどうか
        });

        // 許可書データ
        if (this.processType === PROCESS_TYPE.ECPERMIT) {
          this.$set(this.cloneItems, 'exportCustomsSeparate', {
            exPermitNotifyDataGrp: res,
            remarksGrp: this.cloneItems.exportCustomsSeparate.remarksGrp,
            amendmentGrp: this.cloneItems.exportCustomsSeparate.amendmentGrp
          });
        } else {
          if (_.get(res, 'oilCoalTariffCertNo')) {
            // oilCoalTariffCertNo → oilcoalTariffCertNo 変換対応 TODO:不要になったら削除
            res.oilcoalTariffCertNo = _.get(res, 'oilCoalTariffCertNo');
          }
          this.$set(this.cloneItems, 'importCustomsSeparate', {
            imPermitNotifyDataGrp: res,
            remarksGrp: this.cloneItems.importCustomsSeparate.remarksGrp,
            amendmentGrp: this.cloneItems.importCustomsSeparate.amendmentGrp
          });
        }

        // 許可書商品データ
        const goods = createGoodsLineSeparate({ entityId: this.entityId, processId: this.processId, processSeq: parseInt(this.processSeq), goods: res.goods });
        this.$set(this.cloneItems, 'goodsLineSeparate', {
          goods: goods,
          remarksGrp: this.cloneItems.goodsLineSeparate.remarksGrp,
        });
        // console.log(this.cloneItems)
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    removePermit() {
      this.$store
      .dispatch('SHOW_CONFIRM', 'Are you sure you want to unlink the pool data?')
      .then(() => {
        if (_.get(this.res, 'permitLatest.declarationNo')) {
          // 既に紐づいた許可書がある場合は解除
          this.$set(this.cloneItems, 'permit', {
            permitLinkEditFlg: PERMIT_LINK_EDIT_FLG.DELETE,
          });
        } else {
          // 紐づいた許可書がない場合はpermitオブジェクトごと削除する（初回紐づけ時の解除）
          this.$delete(this.cloneItems, 'permit');
        }
        this.$set(this.cloneItems, 'permitLatest', {});
        if (this.processType === PROCESS_TYPE.ECPERMIT) {
          this.$set(this.cloneItems, 'exportCustomsSeparate', {
            exPermitNotifyDataGrp: {},
            remarksGrp: this.cloneItems.exportCustomsSeparate.remarksGrp,
            amendmentGrp: this.cloneItems.exportCustomsSeparate.amendmentGrp
          });
        } else {
          this.$set(this.cloneItems, 'importCustomsSeparate', {
            imPermitNotifyDataGrp: {},
            remarksGrp: this.cloneItems.importCustomsSeparate.remarksGrp,
            amendmentGrp: this.cloneItems.importCustomsSeparate.amendmentGrp
          });
        }
        this.$set(this.cloneItems, 'goodsLineSeparate', {
          goods: [],
          remarksGrp: this.cloneItems.goodsLineSeparate.remarksGrp,
        });
      })
      .catch(() => {});
    },
    // IP, DNデータを反映
    setIpDn(selection) {
      _.forEach(selection, (row) => {
        const docType = row.docType;

        // リクエスト用パラメータ
        const ipDn = _.get(this.cloneItems, 'ipDn');
        const ipDnItem = {
          senderCompanyId: docType === 'DN' ? row.dndata.senderCompanyId : row.ipdata.senderCompanyId,
          insurancePolicyNo: docType === 'DN' ? row.dndata.insurancePolicyNo : row.ipdata.insurancePolicyNo,
          insurancePolicyBranchNo: docType === 'DN' ? row.dndata.insurancePolicyBranchNo : row.ipdata.insurancePolicyBranchNo,
          ipDnType: docType === 'DN' ? row.dndata.ipDnTypeDn || row.dndata.ipDnType : row.ipdata.ipDnType,
          trx: docType === 'DN' ? row.dndata.trx : row.ipdata.trx,
          ipDnLinkEditFlg: IP_DN_LINK_EDIT_FLG.CREATE,
          docType: docType,
          attachmentFiles: row.attachmentFiles && row.attachmentFiles.length ? row.attachmentFiles : undefined,
        }
        if (ipDn) ipDn.push(ipDnItem);
        else this.$set(this.cloneItems, 'ipDn', [ipDnItem]);

        const user = this.$store.state.userProfile;
        // 画面表示用ipDnLatestオブジェクト
        const ipDnLatest = _.get(this.cloneItems, 'ipDnLatest');
        const ipDnLatestItem = {
          updateUserName: user.userName,
          updateUserIcon: user.userIcon,
          senderCompanyId: docType === 'DN' ? row.dndata.senderCompanyId : row.ipdata.senderCompanyId,
          insurancePolicyNo: docType === 'DN' ? row.dndata.insurancePolicyNo : row.ipdata.insurancePolicyNo,
          insurancePolicyBranchNo: docType === 'DN' ? row.dndata.insurancePolicyBranchNo : row.ipdata.insurancePolicyBranchNo,
          ipDnType: docType === 'DN' ? row.dndata.ipDnTypeDn || row.dndata.ipDnType : row.ipdata.ipDnType,
          docType: row.docType,
          trx: row.trx,
          attachmentFiles: row.attachmentFiles,
          isAddIpDn: true, // ダイアログから選択した許可書かどうか
        }
        if (ipDnLatest) ipDnLatest.push(ipDnLatestItem);
        else this.$set(this.cloneItems, 'ipDnLatest', [ipDnLatestItem]);

        // IP, DNデータ
        // console.log(processData, this.cloneItems)
        if (docType === 'DN') {
          // NOTE: DN紐付けの場合はDN内のIP共通情報はippoolへ登録、DN情報はdnpoolsへ登録する
          const ip = createIpDnData(row.dndata, 'IP', _.get(row, 'dndata.ipDnTypeDn'));
          const ippools = _.get(this.cloneItems, 'linkageMarineInsurance.ippools');
          if (ippools) ippools.push(ip);
          else this.$set(this.cloneItems.linkageMarineInsurance, 'ippools', [ip]);
          // DN情報
          const dn = createIpDnData(row.dndata, docType);
          const dnpools = _.get(this.cloneItems, 'marineInsuranceSeparate.dnpools');
          if (dnpools) dnpools.push(dn);
          else this.$set(this.cloneItems.marineInsuranceSeparate, 'dnpools', [dn]);
        } else {
          // IP情報
          const ip = createIpDnData(row.ipdata, docType);
          const ippools = _.get(this.cloneItems, 'linkageMarineInsurance.ippools');
          if (ippools) ippools.push(ip);
          else this.$set(this.cloneItems.linkageMarineInsurance, 'ippools', [ip]);
        }
        // console.log(this.cloneItems)
      });
      // I/P紐付け時、1件目のI/P情報からプロセス情報へ転記する
      this.cloneItems.linkageMarineInsurance = copyIpDataFromIppools(this.cloneItems.linkageMarineInsurance);
    },
    removeIpDn(row) {
      // リクエスト用パラメータから対象のIP, DNデータを削除
      let ipDn = _.get(this.cloneItems, 'ipDn');
      if (row.isAddIpDn) {
        // Save, Apply前の紐付け解除の場合
        ipDn = _.reject(ipDn, item => {
          return row.senderCompanyId === item.senderCompanyId && row.insurancePolicyNo === item.insurancePolicyNo && row.insurancePolicyBranchNo === item.insurancePolicyBranchNo && row.ipDnType === item.ipDnType;
        });
        this.$set(this.cloneItems, 'ipDn', ipDn);
      } else {
        // Save, Apply後の紐付け解除の場合
        const ipDnItem = {
          senderCompanyId: row.senderCompanyId,
          insurancePolicyNo: row.insurancePolicyNo,
          insurancePolicyBranchNo: row.insurancePolicyBranchNo,
          ipDnType: row.ipDnType,
          trx: row.trx,
          ipDnLinkEditFlg: IP_DN_LINK_EDIT_FLG.DELETE,
          docType: row.docType,
        }
        if (ipDn) ipDn.push(ipDnItem);
        else this.$set(this.cloneItems, 'ipDn', [ipDnItem]);
      }

      // 画面表示用オブジェクトから対象のIP, DNデータを削除
      let ipDnLatest = _.get(this.cloneItems, 'ipDnLatest');
      ipDnLatest = _.reject(ipDnLatest, item => {
        return row.senderCompanyId === item.senderCompanyId && row.insurancePolicyNo === item.insurancePolicyNo && row.insurancePolicyBranchNo === item.insurancePolicyBranchNo && row.ipDnType === item.ipDnType;
      });
      this.$set(this.cloneItems, 'ipDnLatest', ipDnLatest);

      // テーブルから対象のIP, DNデータを削除
      if (row.docType === 'DN') {
        let ippools = _.get(this.cloneItems, 'linkageMarineInsurance.ippools');
        ippools = _.reject(ippools, ip => {
          const ipPolicy = ip.policyGrp;
          // DNから追加したIPかどうか + IPとDNのキー情報が一致しているかどうか
          return (
            ip.ipRegistType === IP_REGIST_TYPE.DN &&
            ipPolicy.ipDnTypeDn === row.ipDnType &&
            row.senderCompanyId === ipPolicy.senderCompanyId &&
            row.insurancePolicyNo === ipPolicy.insurancePolicyNo &&
            row.insurancePolicyBranchNo === ipPolicy.insurancePolicyBranchNo &&
            row.trx === ipPolicy.trx
          );
        });
        this.$set(this.cloneItems.linkageMarineInsurance, 'ippools', ippools);
        let dnpools = _.get(this.cloneItems, 'marineInsuranceSeparate.dnpools');
        dnpools = _.reject(dnpools, item => {
          const policy = item.policyGrp;
          return (
            row.ipDnType === policy.ipDnType &&
            row.senderCompanyId === policy.senderCompanyId &&
            row.insurancePolicyNo === policy.insurancePolicyNo &&
            row.insurancePolicyBranchNo === policy.insurancePolicyBranchNo &&
            row.trx === policy.trx
          );
        });
        this.$set(this.cloneItems.marineInsuranceSeparate, 'dnpools', dnpools);
      } else {
        let ippools = _.get(this.cloneItems, 'linkageMarineInsurance.ippools');
        ippools = _.reject(ippools, item => {
          const policy = item.policyGrp;
          return (
            item.ipRegistType === IP_REGIST_TYPE.IP &&
            row.ipDnType === policy.ipDnType &&
            row.senderCompanyId === policy.senderCompanyId &&
            row.insurancePolicyNo === policy.insurancePolicyNo &&
            row.insurancePolicyBranchNo === policy.insurancePolicyBranchNo &&
            row.trx === policy.trx
          );
        });
        this.$set(this.cloneItems.linkageMarineInsurance, 'ippools', ippools);
      }
    },
    // LCデータを反映
    async setLc(selection) {
      // リクエスト用パラメータ
      let lc = _.get(this.cloneItems, 'lc');
      const lcItems = _.map(selection, item => {
        return {
          sequenceOfDocuments: item.sequenceOfDocuments,
          senderCompanyId: item.senderCompanyId,
          ourReferenceNoLcOriginal: item.ourReferenceNoLcOriginal,
          allNotificationSerialNumbers: item.allNotificationSerialNumbers,
          sequenceNumberOfTotal: item.sequenceNumberOfTotal,
          trx: item.trx,
          messageType: item.messageType,
          lcLinkEditFlg: LC_LINK_EDIT_FLG.CREATE,
        }
      });
      // console.log(lcItems)
      if (lc && lc.length) {
        lc = _.reject(lc, item => {
          return item.lcLinkEditFlg === LC_LINK_EDIT_FLG.CREATE;
        });
        lc = _.concat(lc, lcItems);
        this.$set(this.cloneItems, 'lc', lc);
      } else {
        this.$set(this.cloneItems, 'lc', lcItems);
      }

      const user = this.$store.state.userProfile;
      // 画面表示用lcLatestオブジェクト
      const lcLatest = _.get(this.cloneItems, 'lcLatest');
      if (lcLatest && lcLatest.length > 0) {
        // 既に紐付け済のデータがある場合は解除する
        _.forEach(lcLatest, item => {
          if (!item.isAddLc) {
            const deleteItem = {
              sequenceOfDocuments: item.sequenceOfDocuments,
              senderCompanyId: item.senderCompanyId,
              ourReferenceNoLcOriginal: item.ourReferenceNoLcOriginal,
              allNotificationSerialNumbers: item.allNotificationSerialNumbers,
              sequenceNumberOfTotal: item.sequenceNumberOfTotal,
              trx: item.trx,
              messageType: item.messageType,
              lcLinkEditFlg: LC_LINK_EDIT_FLG.DELETE,
            }
            const lc = _.get(this.cloneItems, 'lc');
            lc.push(deleteItem);
          }
        });
      }
      const lcLatestItems = _.map(selection, item => {
        return {
          updateUserName: user.userName,
          updateUserIcon: user.userIcon,
          sequenceOfDocuments: item.sequenceOfDocuments,
          senderCompanyId: item.senderCompanyId,
          ourReferenceNoLcOriginal: item.ourReferenceNoLcOriginal,
          allNotificationSerialNumbers: item.allNotificationSerialNumbers,
          sequenceNumberOfTotal: item.sequenceNumberOfTotal,
          trx: item.trx,
          messageType: item.messageType,
          isAddLc: true, // ダイアログから選択した許可書かどうか
          documentaryCreditNumber: item.documentaryCreditNumber,
          lcType: this.getLcType(item.messageType),
        }
      });
      this.$set(this.cloneItems, 'lcLatest', lcLatestItems);
      // console.log(lcLatestItems)

      // スタブ
      // let lcpools = this.cloneDeep(lcSub);
      // this.$set(this.cloneItems, 'linkageLc', {
      //   lcpools: lcpools,
      // });
      // console.log(this.cloneItems)
      // return

      // LC詳細取得
      // BFF_LCP04 L/Cプール詳細照会BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/lc-pool-datas/detail',
        },
        data: {
          lcpools: lcLatestItems,
        }
      };
      $api.request(params)
      .then(res => {
        this.$set(this.cloneItems.linkageLc, 'lcpools', res.lcpools);
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
      // console.log(this.cloneItems)
    },
    removeLc(lcLatest) {
      // リクエスト用パラメータからL/Cデータを削除
      let lc = _.get(this.cloneItems, 'lc');
      if (lc && lc.length) {
        lc = _.reject(lc, item => {
          return item.lcLinkEditFlg === LC_LINK_EDIT_FLG.CREATE;
        });
        this.$set(this.cloneItems, 'lc', lc);
      } else {
        // Save, Apply後の紐付け解除の場合
        _.forEach(lcLatest, item => {
          if (!item.isAddLc) {
            const deleteItem = {
              sequenceOfDocuments: item.sequenceOfDocuments,
              senderCompanyId: item.senderCompanyId,
              ourReferenceNoLcOriginal: item.ourReferenceNoLcOriginal,
              allNotificationSerialNumbers: item.allNotificationSerialNumbers,
              sequenceNumberOfTotal: item.sequenceNumberOfTotal,
              trx: item.trx,
              messageType: item.messageType,
              lcLinkEditFlg: LC_LINK_EDIT_FLG.DELETE,
            }
            // lc配列がない場合はセット
            if (!_.get(this.cloneItems, 'lc')) this.$set(this.cloneItems, 'lc', []);
            // 削除するアイテムを追加
            _.get(this.cloneItems, 'lc').push(deleteItem);
          }
        });
      }

      // 画面表示用オブジェクトをリセット
      this.$set(this.cloneItems, 'lcLatest', []);
      // テーブルからLCプールデータを削除
      this.$set(this.cloneItems.linkageLc, 'lcpools', []);
      // console.log(this.cloneItems)
    },
    // 編集モードに
    edit() {
      if (this.$route.name.includes('EditProcess')) {
        return;
      }
      // 他プロセスで更新された最新データフラグがある場合は、再取得の確認Confirmを表示する
      const isLatestProcessInFlow = _.get(this, 'tradeManagement.isLatestProcessInFlow');
      if (isLatestProcessInFlow === IS_LATEST_PROCESS_IN_FLOW.NO) {
        // NOTE: ph6-2時点ではisLatestProcessInFlowフラグはCO申請プロセスでのみ初期表示BFFから返却される
        // BFF,UI双方で将来的には全てのプロセスに同内容を適用する想定である
        // TODO: UI側でCO申請プロセス以外に適用する場合は、各Process.vueのfetchメソッドを修正する必要があります
        this.$store.dispatch('SHOW_CONFIRM', 'There is flow common data updated by other entities. Are you sure you want to overwrite the updated content in another entity?')
          .then(async () => {
            // OK 最新データを再取得し登録・変更ドロワーを表示
            this.$store.commit('START_PROCESS');
            await this.fetch(false, REQUIRES_LATEST_PROCESS_IN_FLOW.YES)
              .finally(() => {
                this.$store.commit('END_PROCESS');
                this.openDrawer();
              });
          })
          .catch(() => {
            // Cancel 最新データを取得せず通常の登録・変更ドロワーを表示
            this.openDrawer();
          });
      } else {
        // 通常の登録・変更ドロワーを表示
        this.openDrawer();
      }
    },
    // 登録・変更ドロワーを表示
    openDrawer() {
      this.$refs.editProcess.documents = [];
      this.$router.replace({
        name: `EditProcess${_.capitalize(this.processId.replace(/\d/g, ''))}`,
        params: { entityId: this.entityId, processSeq: this.processSeq, _processId: this.processId.toLowerCase() },
      });
      if (this.processType === PROCESS_TYPE.FDCOR) this.setCoDocumentSignerInfo();
    },
    // ドロワーを閉じるときの確認
    closeConfirm() {
      if (this.mode === 'mailDetail' || this.mode === 'tradingDetail') {
        this.$store.commit('SET_DRAWER', false);
        setTimeout(() => {
          this.mode = null;
        }, 300);
        return;
      }
      this.$store.dispatch('SHOW_CONFIRM', 'Are you sure to go back? (Unsaved data shall be deleted)')
      .then(() => {
        if (this.mode === 'newMail') {
          this.$store.commit('SET_DRAWER', false);
          setTimeout(() => {
            this.mode = null;
          }, 300);
          return;
        }

        this.$router.replace({
          name: `Process${_.capitalize(this.processId.replace(/\d/g, ''))}`,
          params: { entityId: this.entityId, processSeq: this.processSeq, _processId: this.processId.toLowerCase() },
        });
        setTimeout(() => {
          if (this.couponInfo) {
            // COクーポン情報をリセット
            this.couponInfo = {};
            if (this.couponRequestTag) {
              // COクーポン情報取得BFFキャンセル
              $api.cancelRequests(this.couponRequestTag);
              this.couponRequestTag = null;
            }
          }
          if (this.processType === PROCESS_TYPE.MIRIP) {
            this.cloneItems = this.ipSubmitFlgCheck(this.cloneDeep(this.res));
          } else {
            this.cloneItems = this.cloneDeep(this.res);
          }
          this.$refs.form.clearValidate();
          this.errors = [];
          if (this.$refs.editProcess) {
            this.$refs.editProcess.documents = [];
          }
          this.mode = null;
        }, 300);

      })
      .catch(() => {});
    },
    getTargetName(target, tables) {
      const targetArray = target.split('.');
      if (targetArray.length === 1) {
        return target;
      }
      const table = _.find(tables, {variableName: targetArray[0]});
      if (!table) {
        return target;
      }
      const group = _.find(table.groups, {variableName: targetArray[1]});
      if (!group) {
        return target;
      }
      const item = _.find(group.children, {key: targetArray[2]});
      if (!item) {
        return target;
      }
      return item.label;
    },
    createRelationTarget(target, targetName, tables) {
      if (_.isString(target)) {
        return {
          enemy: target,
          enemyTargetName: targetName || this.getTargetName(target, tables),
        }
      } else {
        return this.createRelationTarget(target[0], targetName, tables);
      }
    },
    /**
     * バリデーションルールを生成します。
     * @param {Array}   tables  テーブル構成
     * @param {Boolean} isDraft 下書きフラグ
     * @returns バリデーションルールオブジェクト
     */
    createRules(tables, isDraft = false) {
      const rules = _.reduce(tables, (rules, table) => {
        if (table.variableName.includes('GoodsLine')) {
          // 商品

        } else {
          // 商品以外
          const groups = _.reduce(table.groups, (groups, group) => {
            const children = _.reduce(group.children, (children, child) => {
              children[child.key] = [
                {
                  required: isDraft ? false : child.validationId.includes('tw-isNotEmpty') || _.some(_.get(child, 'target.relations') || [], o => o.validationId === 'tw-isNotEmptyAorB') ,
                  lakeelMessageSourceFlg: true,
                  validationId: isDraft ? _.reject(child.validationId, s => s === 'tw-isNotEmpty' || s === 'tw-isNotEmptyAorB') : child.validationId,
                  // 相関チェック
                  relation: isDraft ? undefined : child.target && (_.isString(child.target) || _.isArray(child.target)) ? this.createRelationTarget(child.target, child.targetName, tables) : null,
                  validator: this.onEventValidation, trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(child.inputType) || ['SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(child.code)) ? 'change' : 'blur',
                }
              ];
              if (!isDraft && _.isObject(child.target) && !_.isArray(child.target)) {
                _.forEach(child.target.relations, o => {
                  children[child.key].push({
                    lakeelMessageSourceFlg: true,
                    validationId: o.validationId,
                    relation: this.createRelationTarget(o.target, o.targetName, tables)
                  });
                });
              }
              if (child.tsValidationId) {
                const tsValidationIds = _.map(child.tsValidationId, tsValidationId => {
                  return {
                    required: isDraft ? false : tsValidationId.startsWith('ts-isNotEmpty'),
                    validator: this.tsValidation,
                    tsValidationId: isDraft && (tsValidationId.startsWith('ts-isNotEmpty') && tsValidationId !== 'ts-isNotEmptyInvoiceAmountOrIpreqCargoAmount') ? undefined : tsValidationId,
                    trigger: (['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(child.inputType) || ['SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(child.code)) ? 'change' : 'blur',
                  }
                });
                children[child.key] = [...tsValidationIds, ...children[child.key]];
              }

              return children;
            }, {});
            groups[group.variableName] = children;
            return groups;
          }, {});
          rules[table.variableName] = groups;
        }
        return rules;
      }, {});
      // console.log(rules);
      return rules;
    },
    approve() {},
    deleteDraft() {},
    approvalRequest() {},
    register() {},
    withdraw() {},
    submitComment(data) {
      // bff_tc_3 コメント登録BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/common/{companyId}/chats',
          path: {
            companyId: this.companyId
          }
        },
        data: data
      }

      $api.request(params)
      .then(res => {
        this.res.chats.push(_.get(res, 'chats[0]'));
        this.readComment(_.last(this.res.chats).chatManageId);
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    readComment(chatManageId) {
      // bff_tc_4 コメントステータス更新BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'put_/v1/common/chats/readed-flg-status',
          query: {
            chatManageId: [
              chatManageId
            ]
          }
        }
      }

      $api.request(params)
      .then(res => {
        _.forEach(res.chats, o => {
          const index = _.findIndex(this.res.chats, {chatSeq: o.chatSeq, chatManageId: o.chatManegeId});
          if (index > -1) {
            this.$set(this.res.chats, index, o);
          }
        });
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    submitChatImage(chatImageParams) {
      this.$store.commit('START_PROCESS');
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/common/{companyId}/chat-image',
          path: {
            companyId: this.companyId
          }
        },
        data: {
          chatImage: chatImageParams
        }
      };

      return $api.request(params)
      .then(res => {
        this.res.chats.push(_.get(res, 'chats[0]'));
        this.readComment(_.last(this.res.chats).chatManageId);
        return { isError: false };
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
        return { isError: true };
      })
      .finally(() => {
        this.$store.commit('END_PROCESS');
      });
    },
    getFullChatImage(imageFilePath) {
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/common/download-chat-image',
          query: {
            imageFilePath
          }
        }
      };

      return $api.request(params)
      .then(res => {
        return { isError: false, imageData: res.encodedImageString || null };
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
        return { isError: true, imageData: null };
      });
    },
    showMailDetail(noticeHistoryId) {
      this.noticeHistoryId = noticeHistoryId;
      this.mode = 'mailDetail';
      this.$store.commit('SET_DRAWER', true);
    },
    showNewMail() {
      this.mode = 'newMail';
      this.$store.commit('SET_DRAWER', true);
    },
    setMailHistory(list) {
      this.$set(this.res, 'emailTransmissionHistories', list);
    },
    getCommunicationDestinations() {
      // bff_tc_2 コミュニケーション宛先抽出BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/common/communication-destinations/{entityId}/{processId}/{processSeq}',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        tag: this.pushCancelTag(),
      };

      $api.request(params)
      .then(res => {
        this.communicationDestinations = res.communicationDestinations;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    getCurrency(currencyId) {
      return _.get(_.find(this.SYS_MST_CURRENCY, {alphabeticCode: currencyId}), 'symbol');
    },
    getCurrencyMinorUnit(currencyId) {
      return _.get(_.find(this.SYS_MST_CURRENCY, {alphabeticCode: currencyId}), 'minorUnit');
    },
    changeVersion(version) {
      this.versionNo = version;
      this.$router.push({
        name: `Archive${_.capitalize(this.processId.replace(/\d/g, ''))}`,
        params: { entityId: this.tradeManagement.entityId, processSeq: this.tradeManagement.processSeq, processTrx: version, _processId: this.processId.toLowerCase() },
      });
      this.fetch();
    },
    // 排他チェックエラー時にconfirmを出す必要があるかを返却します
    isConflict(err) {
      return err.statusCode === 409 && _.some(this.conflictFlags, e => {return _.some(err.response.errors, error => {return _.get(error, `meta[${e}]`) === false})});
    },
    // 依頼区分をチェックします
    checkTypeOfWorks(group) {
      const typeOfWorks = _.get(this.cloneItems, 'processSeparate.typeOfWorksGrp');
      if (!typeOfWorks) {
        return true;
      }
      return _.some(typeOfWorks, (value, key) => {
        return value === TYPE_OF_WORKS.ON && group[key];
      });
    },
    // 輸送手段に対する項目の出し分け
    checkTransportation(name) {
      // 輸出船積依頼以外は評価しない（将来的には多プロセス、多ターゲットにも対応）
      // NOTE: Ph2 UAT 輸出船積依頼プロセスにのみ実装されている輸送手段による項目出し分け機能を、標準機能として他のプロセスにも実装
      // if (this.processType !== PROCESS_TYPE.ECREQ) {
      //   return true;
      // }
      const value = _.get(this.cloneItems, 'linkageTransportation.transferTermsGrp.transportation');

      if (!_.isNumber(value)) {
        return true;
      }

      const rule = _.find(transportationControl, o => {
        return name === o.variableName;
      });

      if (!rule) {
        return true;
      } else {
        return rule.value.includes(value);
      }
    },
    // 決済手段による項目の出し分け
    checkPaymentTerms(tableName) {
      if (this.processType === PROCESS_TYPE.LCACCEPT) {
        return true;
      }
      const value = _.get(this.cloneItems, 'linkageContract.paymentTermsGrp.paymentId');
      if (!value) {
        return true;
      }
      if (tableName === 'linkageLc' && value !== PAYMENT_TERMS.LC) {
        return false;
      }
      return true;
    },
    // 非表示項目をリクエストから除外します。
    rejectHiddenGroups() {
      const hiddenGroups = _.reduce(this.schemas.tables, (ret, table) => {
        _.forEach(table.groups, group => {
          const name = `${table.variableName}.${group.variableName}`;
          if (!this.checkTypeOfWorks(group)) {
            ret.push(name);
          } else if (!this.checkTransportation(name)) {
            ret.push(name);
          } else if (!this.checkPaymentTerms(table.variableName)) {
            ret.push(name);
          }
        });
        return ret;
      }, []);
      let cloneItems = this.cloneDeep(this.cloneItems);
      _.forEach(hiddenGroups, name => {
        _.set(cloneItems, name, undefined);
      });
      return cloneItems;
    },
    getItemPath(name) {
      name = name || '';
      let path = [];
      _.forEach(this.schemas.tables, table => {
        _.forEach(table.groups, group => {
          const item = _.find(group.children, item => {
            return item.label.toLowerCase().includes(name.toLowerCase());
          });
          if (item) {
            path.push(`${table.variableName}.${group.variableName}.${item.key}`);
            return true;
          }
        });
      });
      return path;
    },
    getItemSchema(path) {
      const ary = path.replace('.arrayContainerSeals', '').replace('.ipreqList', '').replace(/\[\d+\]/, '').split('.');
      const table = _.find(this.schemas.tables, {variableName: ary[0]});
      if (!table) {
        return false;
      }
      const group = _.find(table.groups, {variableName: ary[1]});
      if (!group) {
        return false;
      }
      const item = _.find(group.children, {key: ary[2]});
      return item || false;
    },
    getGroupPath(groupName) {
      groupName = groupName || '';
      let path = [];
      _.forEach(this.schemas.tables, table => {
        const t = _.find(table.groups, group => {
          return group.name.toLowerCase().includes(groupName.toLowerCase());
        });
        if (t) {
          path.push(`${table.variableName}.${t.variableName}`);
        }
      });
      return path;
    },
    // HSコードを返却します
    getHsCode(goodsLineSeq) {
      return _.map(_.filter(this.res.linkageGoodsLineType, (o) => {
        return o.keyGrp.goodsLineSeq === goodsLineSeq && o.keyGrp.attrId === GOODS_ATTR_ID.HS_CODE && o.keyGrp.goodsValue;
      }), o => o.keyGrp.goodsValue).join('/');
    },
    // L/Cメッセージタイプから種別を返却
    getLcType(messageType) {
      return _.get(_.find(lcTypes, item =>{
        const key = item.keys;
        return key.includes(messageType);
      }), 'label', '');
    },
    // 商品明細のリファレンスNO情報群に値をセットします
    setReferenceNoGrp() {
      const table = _.find(this.schemas.tables, {variableName: 'linkageGoodsLine'});
      if (!table || !this.cloneItems.linkageGoodsLine) {
        return;
      }

      const group = _.find(table.groups, {variableName: 'referenceNoGrp'});
      if (!group) {
        return;
      }
      // 商品の入力権限があるときのみ代入
      if (_.some(group.children, item => {
        return !item[this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'] || item.controlType === false;
      })) {
        return;
      }
      const referenceNoGrp = {
        // poNo: _.get(this.cloneItems, 'linkageContract.poNoDateGrp.poNo') || null,
        // contractNo: _.get(this.cloneItems, 'linkageContract.contractNoDateGrp.contractNo') || null,
        // exportInvoiceNo: _.get(this.cloneItems, 'linkageExportCustoms.exCustomClearanceGrp.exCustomInvoiceNo') || null,
        bookingNo: _.get(this.cloneItems, 'linkageTransportation.containerGrp.vesselBookingNo') || null,
        blNo: _.get(this.cloneItems, 'linkageTransportation.blGrp.blNo') || null,
        // commercialInvoiceNo: _.get(this.cloneItems, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceNo') || null,
        // importInvoiceNo: _.get(this.cloneItems, 'linkageImportCustoms.importCustomsClearanceGrp.imCustomInvoiceNo') || null,
      };
      _.forEach(this.cloneItems.linkageGoodsLine, (goods, index) => {
        _.forEach(referenceNoGrp, (value, key) => {
          if (_.get(goods, `referenceNoGrp.${key}`)) {
            return;
          }
          this.setGoodsValue('linkageGoodsLine', index, 'referenceNoGrp', key, value);
        })
      });
    },
    // 拡張子を取得します
    getFileExtension (filename) {
      const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
      return extension.toLowerCase();
    },
    isPdf(doc) {
      return this.getFileExtension(doc.documentName) === 'pdf';
    },
    toBlob(base64, mimeType) {
      // 日本語の文字化けに対処するためBOMを作成する。
      var bom = new Uint8Array([0xef, 0xbb, 0xbf]);

      var bin = window.atob(base64.replace(/^.*,/, ''));
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      // Blobを作成
      try {
        var blob = new Blob([bom, buffer.buffer], {
          type: mimeType,
        });
      } catch (e) {
        return false;
      }
      return blob;
    },
    previewPdf(doc) {
      if (doc.documentValue) {
        const blob = this.toBlob(doc.documentValue, 'application/pdf');
        if (blob) {
          this.pdfSrc = window.URL.createObjectURL(blob);
        }
        return;
      }

      this.pdfSrc = '@';

      this.pdfRequestTag = this.pushCancelTag();

      const path = {
        documentManageId: doc.documentManageId,
        documentTrx: doc.documentTrx,
        tag: this.pdfRequestTag,
      };

      $api
      .download(path, doc.documentName, null, null, false)
      .then((src) => {
        this.pdfSrc = src;
      })
      .catch(err => {
        if (err.isCanceled) {
          return;
        }
        this.$store.dispatch('SHOW_ALERT', err.message);
      });

    },
    closePdfViewer() {
      window.URL.revokeObjectURL(this.pdfSrc);
      this.pdfSrc = null;
      $api.cancelRequests(this.pdfRequestTag);
      this.pdfRequestTag = null;
    },
    // 該当項目かを判定します
    isTargetItem(str, table, group, key) {
      return [table, group, key].join('.') === str;
    },
    // IP項目生成
    createIpItems(tables) {
      const ret = _.map(tables.linkageMarineInsurance.ippools, pool => {
        return {
          docType: 'I/P',
          senderCompanyId: pool.policyGrp.senderCompanyId,
          ipRegistType: pool.ipRegistType,
          ipDnType: pool.policyGrp.ipDnType,
          ipDnTypeDn: pool.policyGrp.ipDnTypeDn,
          shipperReferenceNo: pool.policyGrp.shipperReferenceNo,
          opOcProvNo: pool.policyGrp.opOcProvNo,
          insurancePolicyNo: pool.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: pool.policyGrp.insurancePolicyBranchNo,
          invoiceNo: pool.invoiceGrp.invoiceNo,
          assuredsEtc: pool.assuredGrp.assuredsEtc,
        }
      });
      return ret;
    },
    // DN項目生成
    createDnItems(tables) {
      const ret = _.map(tables.marineInsuranceSeparate.dnpools, dn => {
        const targetIp = _.find(tables.linkageMarineInsurance.ippools, ip => {
          // DNから追加したIP情報を検索
          const ipPolicy = ip.policyGrp;
          const dnPolicy = dn.policyGrp;
          // DNから追加したIPかどうか + IPとDNのキー情報が一致しているかどうか
          return (
            ip.ipRegistType === IP_REGIST_TYPE.DN &&
            ipPolicy.ipDnTypeDn === dnPolicy.ipDnType &&
            ipPolicy.senderCompanyId === dnPolicy.senderCompanyId &&
            ipPolicy.insurancePolicyNo === dnPolicy.insurancePolicyNo &&
            ipPolicy.insurancePolicyBranchNo === dnPolicy.insurancePolicyBranchNo &&
            ipPolicy.trx === dnPolicy.trx
          );
        });
        return {
          docType: 'D/N',
          senderCompanyId: dn.policyGrp.senderCompanyId,
          ipDnType: targetIp ? targetIp.policyGrp.ipDnType : '',
          ipDnTypeDn: dn.policyGrp.ipDnType,
          insurancePolicyNo: dn.policyGrp.insurancePolicyNo,
          insurancePolicyBranchNo: dn.policyGrp.insurancePolicyBranchNo,
          shipperReferenceNo: targetIp ? targetIp.policyGrp.shipperReferenceNo : '',
          opOcProvNo: targetIp ? targetIp.policyGrp.opOcProvNo : '',
          invoiceNo: targetIp ? targetIp.invoiceGrp.invoiceNo : '',
          assuredsEtc: targetIp ? targetIp.assuredGrp.assuredsEtc : '',
        }
      });
      return ret;
    },
    // 取引詳細を表示します
    showTradingDetail(flow) {
      this.parentTradingId = flow.parentTradingId;
      this.parentFlowId = flow.parentFlowId;
      this.$store.commit('SET_DRAWER', true);
      this.mode = 'tradingDetail';
    },
    // PB-734
    // ファイル添付モーダルからファイルの登録・削除をおこなったあと、
    // 各プロセスのプロセス初期表示BFFを実行
    updateAttachedFiles(){
      this.fetch();
    }
  }
};
